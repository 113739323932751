import {
    COMPANIES_EVALUATION_FINISH_STEP_REQUESTING, COMPANIES_EVALUATION_GET_ME_REQUESTING,
    COMPANIES_EVALUATION_REMOVE_CURRENT_EVALUATION,
    COMPANIES_EVALUATION_SET_CURRENT_REQUESTING,
    COMPANIES_EVALUATION_STORE_ANSWER_REQUESTING,
    COMPANIES_EVALUATION_UPDATE_LOCAL_ANSWER
} from "./constants";

export const getCompaniesEvaluationMeRequest = () => {
    return {
        type: COMPANIES_EVALUATION_GET_ME_REQUESTING
    }
};

export const setCurrentCompaniesEvaluation = (companiesEvaluationId) => {
    return {
        type: COMPANIES_EVALUATION_SET_CURRENT_REQUESTING,
        companiesEvaluationId: parseInt(companiesEvaluationId)
    }
}

export const removeCurrentCompaniesEvaluation = () => {
    return {
        type: COMPANIES_EVALUATION_REMOVE_CURRENT_EVALUATION
    }
};

export const onUpdateCompaniesEvaluationAnswer = (stepId, caseStepId, userId, value) => {
    return {
        type: COMPANIES_EVALUATION_UPDATE_LOCAL_ANSWER,
        stepId: stepId,
        caseStepId: caseStepId,
        userId: userId,
        value: value ? parseInt(value) : 0
    }
};

export const onStoreCompaniesEvaluationAnswer = (stepId, caseStepId, userId, value) => {
    return {
        type: COMPANIES_EVALUATION_STORE_ANSWER_REQUESTING,
        stepId: stepId,
        caseStepId: caseStepId,
        userId: userId,
        value: value ? parseInt(value) : 0
    }
};

export const finishCompaniesAnsweringStep = (stepId) => {
    return {
        type: COMPANIES_EVALUATION_FINISH_STEP_REQUESTING,
        stepId: parseInt(stepId)
    }
};

