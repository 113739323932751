import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBBtn
} from "mdbreact";
import {logoutRequest} from "../auth/actions";

class Header extends React.Component {
    state = {
        isOpen: false
    };

    toggleCollapse = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    logout = (event) => {
        this.props.logoutRequest();
    }

    goHome = () => {
        this.props.history.push("/dashboard");
    }

    admin = () => {
        this.props.history.push("/admin");
    }

    render() {
        const { user, pathname } = this.props;

        return (
            <MDBNavbar color="blue" dark expand="md">
                <MDBNavbarBrand onClick={this.goHome} className="pointer">
                    <strong className="white-text">ImpactTool</strong>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={this.toggleCollapse}/>
                <MDBCollapse id="navbarCollapse" isOpen={this.state.isOpen} navbar>
                    <MDBNavbarNav left>
                        <MDBNavItem className={pathname==='/dashboard' ? 'active' : ''}>
                            <MDBNavLink to="/dashboard">Hjem</MDBNavLink>
                        </MDBNavItem>
                    </MDBNavbarNav>
                    <MDBNavbarNav right>
                        <MDBNavItem>
                            { user && user.roles.some(obj => obj.roleName==='ADMIN')
                                ? <MDBBtn flat className="text-white" size="sm" onClick={this.admin}>Admin</MDBBtn>
                                : ""
                            }
                        </MDBNavItem>
                        <MDBNavItem>
                            { user ? <MDBBtn flat className="text-white" size="sm">{user.firstName}</MDBBtn> : ""}
                        </MDBNavItem>
                        <MDBNavItem active>
                            {user ? <MDBBtn color="blue" size="sm" onClick={this.logout}>Logg ut</MDBBtn> : null}
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.module.user,
    pathname: state.router.location.pathname,
});

const connected = connect(mapStateToProps, {logoutRequest})(Header);

export default withRouter(connected);
