import {connect} from "react-redux";
import {withRouter} from "react-router";
import React, {useState} from "react";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBInput,
    MDBMask,
    MDBRow,
    MDBView
} from "mdbreact";
import {API_BASE_URL} from "../../../constants";
import {Fetch} from "../../../common/lib/api-fetch";
import {showInfo} from "../../../common/lib/show-error";

const ResetPassword = ({history, match}) => {
    const [form, setForm] = useState({
        email: {changed: false, value: ''},
        newPassword: {changed: false, value: ''},
        newPassword2: {changed: false, value: ''}
    });

    const token = match.params.token;

    const submit = async (event) => {
        event.preventDefault();
        try {
            await Fetch(API_BASE_URL + "/auth/resetPassword", 'POST', {
                token: token,
                password: form.newPassword.value
            }).then(result => {
                showInfo("Passordet ditt er endret");
                history.push("/login");
            })
        } catch(error) {
            showInfo("Noe gikk galt i endringen av passordet");
        }
    };

    const changeHandler = event => {
        setForm({
            ...form,
            [event.target.name]: {changed: true, value: event.target.value}
        })
    };

    return (
        <>
            <div>
                <form className="needs-validation" onSubmit={submit} noValidate>
                    <div className="classic-form-page" id="login">
                        <MDBView>
                            <MDBMask
                                className="d-flex justify-content-center align-items-center"
                                overlay="stylish-strong"
                            >
                                <MDBContainer>
                                    <MDBRow>
                                        <MDBCol md="10" lg="6" xl="5" sm="12" className="mt-5 mx-auto">
                                            <MDBCard>
                                                <MDBCardBody>
                                                    <div className="form-header blue-gradient">
                                                        <h3><MDBIcon icon="user"
                                                                     className="mt-2 mb-2 text-white"/> Sett passord</h3>
                                                    </div>
                                                    <MDBInput
                                                        value={form.newPassword.value}
                                                        name="newPassword"
                                                        className={form.newPassword.changed && form.newPassword2.changed ? form.newPassword.value===form.newPassword2.value ? 'is-valid' : 'is-invalid' : ''}
                                                        onChange={changeHandler}
                                                        type="password"
                                                        label="Passord"
                                                        icon="lock"
                                                        labelClass={"black-text"}
                                                        iconClass="black-text">
                                                        <div className="invalid-feedback">Passordene må være like</div>
                                                    </MDBInput>
                                                    <MDBInput
                                                        value={form.newPassword2.value}
                                                        name="newPassword2"
                                                        className={form.newPassword.changed && form.newPassword2.changed ? form.newPassword.value===form.newPassword2.value ? 'is-valid' : 'is-invalid' : ''}
                                                        onChange={changeHandler}
                                                        type="password"
                                                        label="Passord igjen"
                                                        icon="lock"
                                                        labelClass={"black-text"}
                                                        iconClass="black-text">
                                                        <div className="invalid-feedback">Passordene må være like</div>
                                                    </MDBInput>
                                                    <div className="text-center mt-3 black-text">
                                                        <MDBBtn className="blue-gradient" size="lg" type="submit"
                                                                disabled={!form.newPassword.changed || !form.newPassword2.changed || form.newPassword.value !== form.newPassword2.value}
                                                        >Endre passord</MDBBtn>
                                                        <hr/>
                                                    </div>
                                                    <div
                                                        className="inline-ul text-center d-flex justify-content-center">

                                                        <img src="/images/grensesnitt-logo.svg" height={30}
                                                             className="mx-auto d-block spacing logo" alt="logo"/>
                                                    </div>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBMask>
                        </MDBView>
                    </div>
                </form>
            </div>
            </>
                )
}

const mapStateToProps = state => ({
});

const connected = connect(mapStateToProps, {})(ResetPassword);

export default withRouter(connected);
