import {
    COMPANIES_EVALUATION_FINISH_STEP_ERROR,
    COMPANIES_EVALUATION_FINISH_STEP_REQUESTING,
    COMPANIES_EVALUATION_FINISH_STEP_SUCCESS,
    COMPANIES_EVALUATION_GET_ERROR,
    COMPANIES_EVALUATION_GET_ME_ERROR,
    COMPANIES_EVALUATION_GET_ME_REQUESTING,
    COMPANIES_EVALUATION_GET_ME_SUCCESS,
    COMPANIES_EVALUATION_GET_REQUESTING,
    COMPANIES_EVALUATION_GET_SUCCESS,
    COMPANIES_EVALUATION_REMOVE_CURRENT_EVALUATION, COMPANIES_EVALUATION_RESET_STATE,
    COMPANIES_EVALUATION_SET_CURRENT_ERROR,
    COMPANIES_EVALUATION_SET_CURRENT_REQUESTING,
    COMPANIES_EVALUATION_SET_CURRENT_SUCCESS,
    COMPANIES_EVALUATION_STORE_ANSWER_ERROR,
    COMPANIES_EVALUATION_STORE_ANSWER_REQUESTING,
    COMPANIES_EVALUATION_STORE_ANSWER_SUCCESS,
    COMPANIES_EVALUATION_UPDATE_LOCAL_ANSWER
} from "./constants";

const initialState = {
    errors: "",
    companiesEvaluations: [],
    current: {
        companiesEvaluation: null
    }
};

const reducer = (state = initialState, action) => {
    let steps, step;
    switch (action.type) {
        case COMPANIES_EVALUATION_RESET_STATE:
            return {
                ...initialState
            }

        case COMPANIES_EVALUATION_GET_ME_REQUESTING:
            return {
                ...state,
                errors: ""
            };

        case COMPANIES_EVALUATION_GET_ME_SUCCESS:
            return {
                ...state,
                companiesEvaluations: action.companiesEvaluations,
                errors: ""
            };

        case COMPANIES_EVALUATION_GET_ME_ERROR:
            return {
                ...state,
                companiesEvaluations: [],
                errors: action.error.toString()
            }

        case COMPANIES_EVALUATION_GET_SUCCESS:
            return {
                ...state,
                [action.companiesEvaluation.id]: action.companiesEvaluation,
                errors: ""
            };
        case COMPANIES_EVALUATION_GET_REQUESTING:
            return {
                ...state,
                errors: ""
            };

        case COMPANIES_EVALUATION_GET_ERROR:
            return {
                ...state,
                errors: action.error.toString(),
            };

        case COMPANIES_EVALUATION_SET_CURRENT_REQUESTING:
            return {
                ...state,
                errors: ""
            };

        case COMPANIES_EVALUATION_SET_CURRENT_SUCCESS:
            return {
                ...state,
                current: {
                    ...state.current,
                    companiesEvaluation: action.companiesEvaluation,
                },
                errors: ""
            };

        case COMPANIES_EVALUATION_SET_CURRENT_ERROR:
            return {
                ...state,
                error: action.error.toString(),
            };

        case COMPANIES_EVALUATION_REMOVE_CURRENT_EVALUATION:
            return {
                ...state,
                current: {
                    ...state.current,
                    companiesEvaluation: null
                },
                errors: ""
            };

        case COMPANIES_EVALUATION_UPDATE_LOCAL_ANSWER:
            steps = Object.assign([], state.current.companiesEvaluation.steps);
            step = steps.find(s => s.id === action.stepId);
            step.answers = step.answers
                .filter(answer => answer.caseStepId !== action.caseStepId || answer.userId !== action.userId);
            step.answers.push({
                companiesStepId: step.id,
                caseStepId: action.caseStepId,
                userId: action.userId,
                value: action.value
            });
            return {
                ...state,
                current: {
                    ...state.current,
                    companiesEvaluation: {
                        ...state.current.companiesEvaluation,
                        steps: steps
                    }
                }
            };

        case COMPANIES_EVALUATION_STORE_ANSWER_REQUESTING:
            return {
                ...state,
                errors: ""
            };

        case COMPANIES_EVALUATION_STORE_ANSWER_ERROR:
            return {
                ...state,
                errors: action.error.toString()
            };

        case COMPANIES_EVALUATION_STORE_ANSWER_SUCCESS:
            steps = Object.assign([], state.current.companiesEvaluation.steps);
            step = steps.find(s => s.id === action.companiesStepId);
            step.answers = step.answers
                .filter(answer => answer.caseStepId !== action.caseStepId || answer.userId !== action.userId);
            step.answers.push({
                companiesStepId: step.id,
                caseStepId: action.caseStepId,
                userId: action.userId,
                value: action.value
            });
            return {
                ...state,
                current: {
                    ...state.current,
                    companiesEvaluation: {
                        ...state.current.companiesEvaluation,
                        steps: steps
                    }
                }
            };

        case COMPANIES_EVALUATION_FINISH_STEP_REQUESTING:
            return {
                ...state,
                error: ""
            };

        case COMPANIES_EVALUATION_FINISH_STEP_SUCCESS:
            console.log("Finishing step reducer", action);
            steps = Object.assign([], state.current.companiesEvaluation.steps);
            step = steps.find(s => s.id === action.data.stepId);
            if (!step) return {...state};

            console.log("Got step");
            step.users.find(user => user.userId === action.data.userId).finished = action.data.finished;
            return {
                ...state,
                current: {
                    ...state.current,
                    companiesEvaluation: {
                        ...state.current.companiesEvaluation,
                        steps: steps
                    }
                }
            };

        case COMPANIES_EVALUATION_FINISH_STEP_ERROR:
            console.log("Error step");
            return {
                ...state,
                error: action.error.toString()
            };


        default:
            return state;

    }
}

export default reducer;