import {call, put, all, takeEvery, takeLatest, select} from 'redux-saga/effects';

import {API_BASE_URL, AUTH_LOGOUT_SUCCESS} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import {
    EVALUATION_FINISH_STEP_ERROR,
    EVALUATION_FINISH_STEP_REQUESTING,
    EVALUATION_FINISH_STEP_SUCCESS,
    EVALUATION_GET_ERROR,
    EVALUATION_GET_REQUESTING,
    EVALUATION_GET_SUCCESS,
    EVALUATION_RESET_STATE,
    EVALUATION_SET_CURRENT_ERROR,
    EVALUATION_SET_CURRENT_REQUESTING,
    EVALUATION_SET_CURRENT_SUCCESS,
    EVALUATION_STEP_SET_CURRENT_ERROR,
    EVALUATION_STEP_SET_CURRENT_REQUESTING,
    EVALUATION_STEP_SET_CURRENT_SUCCESS,
    EVALUATION_STORE_ANSWER_ERROR,
    EVALUATION_STORE_ANSWER_REQUESTING,
    EVALUATION_STORE_ANSWER_SUCCESS,
    EVALUATION_STORE_COMMENT_ERROR,
    EVALUATION_STORE_COMMENT_REQUESTING,
    EVALUATION_STORE_COMMENT_SUCCESS
} from "./constants";
import {setCurrentEvaluationStep} from "./actions";


function apiEvaluationGet(evaluationId) {
    return Fetch(API_BASE_URL + "/evaluation/" + evaluationId, 'GET', {});
}

function apiStoreAnswer(questionId, stepId, value, info) {
    const obj = {
        questionId: parseInt(questionId),
        stepId: parseInt(stepId),
        value: parseInt(value),
        info: "" + info
    }
    return Fetch(API_BASE_URL + "/evaluation/answer", "PUT", obj);
}

function apiFinishStep(stepId) {
    return Fetch(API_BASE_URL + "/evaluation/step/finished", "PUT", {stepId: stepId});
}

function apiStoreComment(evaluationId, comment) {
    const obj = {
        evaluationId: parseInt(evaluationId),
        comment: comment
    };
    return Fetch(API_BASE_URL + "/evaluation/comment", "PUT", obj);
}

function* evaluationGetWatcher({evaluationId}) {
    let evaluation;

    try {
        let result = yield call(apiEvaluationGet, evaluationId);
        evaluation = result.data;
        yield put({type: EVALUATION_GET_SUCCESS, evaluation});
    } catch (error) {
        yield put({type: EVALUATION_GET_ERROR, error})
    }
    return evaluation;
}

function* setCurrentEvaluationWatcher({evaluationId}) {
    let evaluation;

    const state = yield select();
    console.log(state);
//    if (evaluationId in state.evaluation) {
//        evaluation = state.evaluation[evaluationId];
//    } else {
    evaluation = yield call(evaluationGetWatcher, {evaluationId});
//    }
    if (evaluation) {
        yield put({type: EVALUATION_SET_CURRENT_SUCCESS, evaluation});
        if (evaluation.steps.length === 1) {
            const nstate = yield select();
            if (!nstate.evaluation.current.step || nstate.evaluation.current.step.id !== evaluation.steps[0].id)
                yield put(setCurrentEvaluationStep(evaluation.id, evaluation.steps[0].id));
        }
        return evaluation;
    } else {
        yield put({type: EVALUATION_SET_CURRENT_ERROR, error: "unknown"});
    }
    return evaluation
}

function* setCurrentEvaluationStepWatcher({evaluationId, stepId}) {
    let evaluation = yield call(setCurrentEvaluationWatcher, {evaluationId});
    if (evaluation) {
        let step = evaluation.steps.find(step => step.id === stepId);
        if (step) {
            yield put({type: EVALUATION_STEP_SET_CURRENT_SUCCESS, step});
        } else {
            yield put({type: EVALUATION_STEP_SET_CURRENT_ERROR, error: "Unknown step"});
        }
    }
}

function* storeAnswerToServerWatcher({questionId, stepId, value, info}) {
    let answer;
    try {
        const result = yield call(apiStoreAnswer, questionId, stepId, value, info);
        answer = result.data;
        yield put({type: EVALUATION_STORE_ANSWER_SUCCESS, answer});
    } catch (error) {
        yield put({type: EVALUATION_STORE_ANSWER_ERROR, error});
    }
}

function* finishStepWatcher({stepId}) {
    let data;
    try {
        const result = yield call(apiFinishStep, stepId);
        data = result.data;
        yield put({type: EVALUATION_FINISH_STEP_SUCCESS, data});
    } catch (error) {
        yield put({type: EVALUATION_FINISH_STEP_ERROR, error});
    }
}

function* storeCommentToServerWatcher({evaluationId, comment}) {
    try {
        const result = yield call(apiStoreComment, evaluationId, comment);
        console.log("Store comment result", result);
        yield put({type: EVALUATION_STORE_COMMENT_SUCCESS, data: result.data});
    } catch (error) {
        yield put({type: EVALUATION_STORE_COMMENT_ERROR, error});
    }
}

function* onLogout() {
    yield put({type: EVALUATION_RESET_STATE});
}

function* watchAll() {
    yield all([
        takeLatest(EVALUATION_GET_REQUESTING, evaluationGetWatcher),
        takeEvery(EVALUATION_SET_CURRENT_REQUESTING, setCurrentEvaluationWatcher),
        takeEvery(EVALUATION_STEP_SET_CURRENT_REQUESTING, setCurrentEvaluationStepWatcher),
        takeEvery(EVALUATION_STORE_ANSWER_REQUESTING, storeAnswerToServerWatcher),
        takeEvery(EVALUATION_FINISH_STEP_REQUESTING, finishStepWatcher),
        takeEvery(AUTH_LOGOUT_SUCCESS, onLogout),
        takeEvery(EVALUATION_STORE_COMMENT_REQUESTING, storeCommentToServerWatcher),
    ]);
}

export default function* IndexSaga() {
    yield all([
        watchAll(),
    ])
}