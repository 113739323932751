import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {loginRequest} from "./actions";

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBMask,
    MDBInput,
    MDBIcon,
    MDBView,
    MDBBtn,
    MDBAlert
} from 'mdbreact';
import "./index.css";
import {Modal} from 'react-bootstrap';
import {Fetch} from "../../../common/lib/api-fetch";
import {API_BASE_URL} from "../../../constants";
import {showError, showInfo} from "../../../common/lib/show-error";


const AuthLogin = ({loginRequest, login}) => {
    const [form, setForm] = useState({
        email: {changed: false, value: ''},
        password: {changed: false, value: ''}
    });
    const [resetPassword, setResetPassword] = useState(false);
    const [resetEmail, setResetEmail] = useState('');

    const submit = (event) => {
        event.preventDefault();
        loginRequest({email: form.email.value, password: form.password.value});
    };

    const changeHandler = event => {
        setForm({
            ...form,
            [event.target.name]: {changed: true, value: event.target.value}
        })
    };

     const doResetPassword = async () => {
        try {
            await Fetch(API_BASE_URL+"/auth/resetPasswordRequest", 'POST', {email: resetEmail}).then( result => {
                    setResetPassword(false);
                    showInfo("Forespørsel har blitt sendt");
                }
            );
        } catch(error) {
            console.log("Could not send request");
            setResetPassword(false);
            showInfo('Kunne ikke sende forespørsel');
        };
    }

    return (
        <>
            <div>
                <form className="needs-validation" onSubmit={submit} noValidate>
                    <div className="classic-form-page" id="login">
                        <MDBView>
                            <MDBMask
                                className="d-flex justify-content-center align-items-center"
                                overlay="stylish-strong"
                            >
                                <MDBContainer>
                                    <MDBRow>
                                        <MDBCol md="10" lg="6" xl="5" sm="12" className="mt-5 mx-auto">
                                            <MDBCard>
                                                <MDBCardBody>
                                                    <div className="form-header blue-gradient">
                                                        <h3><MDBIcon icon="user"
                                                                     className="mt-2 mb-2 text-white"/> Logg
                                                            inn:</h3>
                                                    </div>
                                                    <MDBInput
                                                        value={form.email.value}
                                                        name="email"
                                                        className={!form.email.changed ? "" : form.email.value ? "is-valid" : "is-invalid"}
                                                        onChange={changeHandler}
                                                        type="email"
                                                        label="Din e-post"
                                                        icon="envelope"
                                                        iconClass="black-text"
                                                        labelClass={"black-text"}
                                                        required
                                                    >
                                                        <div className="invalid-feedback">Trenger e-post</div>
                                                    </MDBInput>
                                                    <MDBInput
                                                        value={form.password.value}
                                                        name="password"
                                                        className={!form.password.changed ? "" : form.password.value ? "is-valid" : "is-invalid"}
                                                        onChange={changeHandler}
                                                        type="password"
                                                        label="Passord"
                                                        icon="lock"
                                                        labelClass={"black-text"}
                                                        iconClass="black-text">
                                                        <div className="invalid-feedback">Trenger passord</div>
                                                    </MDBInput>
                                                    <div className="text-center mt-3 black-text">
                                                        <MDBBtn className="blue-gradient" size="lg" type="submit">LOGG
                                                            INN</MDBBtn>
                                                        <p className="black-text"
                                                           style={{fontWeight: 300, fontSize: '0.7rem', cursor: 'pointer'}}
                                                           onClick={() => setResetPassword(true)}>Glemt
                                                            passordet ditt?</p>
                                                        <hr/>
                                                    </div>
                                                    <MDBContainer>
                                                        {login.errors.length > 0 ?
                                                            <MDBAlert color="danger" dismiss>
                                                                {login.errors}
                                                            </MDBAlert>
                                                            : null}

                                                    </MDBContainer>
                                                    <div
                                                        className="inline-ul text-center d-flex justify-content-center">

                                                        <img src="images/grensesnitt-logo.svg" height={30}
                                                             className="mx-auto d-block spacing logo" alt="logo"/>
                                                    </div>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBMask>
                        </MDBView>
                    </div>
                </form>
                <Modal show={resetPassword} onHide={() => setResetPassword(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Mistet passordet ditt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Skriv inn epost-adressen din, og du vil bli tilsendt en lenke for å endre passordet ditt.
                        <MDBInput
                            value={resetEmail}
                            name="resetEmail"
                            onChange={(event) => setResetEmail(event.target.value)}
                            label="Epost-adresse"
                            icon="lock"
                            iconClass="white-text">
                        </MDBInput>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-around">
                            <MDBBtn onClick={() => doResetPassword()} className="blue-gradient">
                                Be om nytt passord
                            </MDBBtn>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

AuthLogin.propTypes = {
    loginRequest: PropTypes.func,
    login: PropTypes.shape({
        requesting: PropTypes.bool,
        successful: PropTypes.bool,
        messages: PropTypes.string,
        errors: PropTypes.string,
    }),
};

const mapStateToProps = state => ({
    login: state.auth.login,
});

const connected = connect(mapStateToProps, {loginRequest})(AuthLogin);

export default withRouter(connected);
