import {useEffect, useState} from 'react';

const _ = require('underscore');

const useForm = (initialState = {}, callback, validate) => {

    const [original, setOriginal] = useState({});
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [changed, setChanged] = useState({});

    useEffect(() => {
        console.log("Initial", initialState, original);
            if (!_.isEmpty(initialState) && !_.isEqual(initialState, original)) {
                setValues(initialState);
                setOriginal(initialState);
            }
        },
        [initialState]
    );

    useEffect(() => {
        setErrors(validate(values));
        if (!_.isMatch(original, values)) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [values]);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            setIsSubmitting(false);
            callback();
        }
    }, [errors]);

    const handleKey = key => value => {
        handleKeyValue(key, value);
    };

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setIsSubmitting(true);
        setErrors(validate(values));
    };

    const handleChange = (event) => {
        event.persist();
        const key = event.target.name;
        const value = event.target.value;

        handleKeyValue(key, value);
    }

    const handleKeyValue = (key, value) => {
        if (original[key] !== value) {
            setChanged({...changed, [key]: value});
        } else {
            let c = {...changed};
            delete c[key];
            setChanged(c);
        }
        setValues(values => ({...values, [key]: value}));
    };

    const doValidation = () => {
        setErrors(validate(values));
    }

    return {
        values,
        errors,
        isChanged,
        changed,
        handleKey,
        handleChange,
        handleSubmit,
        doValidation
    }
};

export default useForm;
