import {
    EVALUATION_FINISH_STEP_ERROR,
    EVALUATION_FINISH_STEP_REQUESTING,
    EVALUATION_FINISH_STEP_SUCCESS,
    EVALUATION_GET_ERROR,
    EVALUATION_GET_REQUESTING,
    EVALUATION_GET_SUCCESS,
    EVALUATION_REMOVE_CURRENT_EVALUATION,
    EVALUATION_REMOVE_CURRENT_STEP,
    EVALUATION_RESET_STATE,
    EVALUATION_SET_CURRENT_ERROR,
    EVALUATION_SET_CURRENT_REQUESTING,
    EVALUATION_SET_CURRENT_SUCCESS,
    EVALUATION_STEP_SET_CURRENT_ERROR,
    EVALUATION_STEP_SET_CURRENT_REQUESTING,
    EVALUATION_STEP_SET_CURRENT_SUCCESS,
    EVALUATION_STORE_ANSWER_ERROR,
    EVALUATION_STORE_ANSWER_REQUESTING,
    EVALUATION_STORE_ANSWER_SUCCESS,
    EVALUATION_STORE_COMMENT_ERROR, EVALUATION_STORE_COMMENT_LOCAL,
    EVALUATION_STORE_COMMENT_REQUESTING,
    EVALUATION_STORE_COMMENT_SUCCESS,
} from "./constants";

const initialState = {
    errors: "",
    current: {
        evaluation: null,
        step: null
    }
};

const reducer = (state = initialState, action) => {
    let answer, answers, comments;
    switch (action.type) {
        case EVALUATION_RESET_STATE:
            return {
                ...initialState
            };
        case EVALUATION_GET_SUCCESS:
            return {
                ...state,
                [action.evaluation.id]: action.evaluation,
                errors: ""
            };
        case EVALUATION_GET_REQUESTING:
            return {
                ...state,
                errors: ""
            };

        case EVALUATION_GET_ERROR:
            return {
                ...state,
                errors: action.error.toString(),
            };

        case EVALUATION_SET_CURRENT_REQUESTING:
            return {
                ...state,
                errors: ""
            };

        case EVALUATION_SET_CURRENT_SUCCESS:
            return {
                ...state,
                current: {
                    ...state.current,
                    evaluation: action.evaluation,
                },
                errors: ""
            };

        case EVALUATION_SET_CURRENT_ERROR:
            return {
                ...state,
                errors: action.error.toString(),
            };

        case EVALUATION_STEP_SET_CURRENT_REQUESTING:
            return {
                ...state,
                errors: ""
            };

        case EVALUATION_STEP_SET_CURRENT_SUCCESS:
            return {
                ...state,
                current: {
                    ...state.current,
                    step: action.step,
                },
                errors: ""
            };

        case EVALUATION_STEP_SET_CURRENT_ERROR:
            return {
                ...state,
                error: action.error.toString(),
            };

        case EVALUATION_REMOVE_CURRENT_STEP:
            return {
                ...state,
                current: {
                    ...state.current,
                    step: null
                },
                errors: ""
            };

        case EVALUATION_REMOVE_CURRENT_EVALUATION:
            return {
                ...state,
                current: {
                    ...state.current,
                    evaluation: null
                },
                errors: ""
            }

        case EVALUATION_STORE_ANSWER_REQUESTING:
            return {
                ...state,
                errors: ""
            };

        case EVALUATION_STORE_ANSWER_SUCCESS:
            answer = action.answer;
            // If wrong step, we don't store
            if (state.current.step.id !== answer.stepId)
                return { ...state };
            // Remove possible earlier answer and add the new one
            answers = state.current.step.answers.filter(a => a.questionId!==answer.questionId || a.userId!==answer.userId);
            answers.push(answer);
            return {
                ...state,
                current: {
                    ...state.current,
                    step: {
                        ...state.current.step,
                        answers: answers
                    }
                },
                errors: ""
            };

        case EVALUATION_STORE_ANSWER_ERROR:
            return {
                ...state,
                errors: action.error.toString()
            };

        case EVALUATION_FINISH_STEP_REQUESTING:
            return {
                ...state,
                errors: ""
            };

        case EVALUATION_FINISH_STEP_SUCCESS:
            if (state.current.step.id !== action.data.stepId)
                return { ...state };

            let users = Object.assign([], state.current.step.users);
            const user = users.find(user => user.userId === action.data.userId);
            user.finished = action.data.finished;
            return {
                ...state,
                current: {
                    ...state.current,
                    step: {
                        ...state.current.step,
                        users: users
                    }
                }
            };

        case EVALUATION_FINISH_STEP_ERROR:
            return {
                ...state,
                error: action.error.toString()
            };

        case EVALUATION_STORE_COMMENT_REQUESTING:
            return {
                ...state,
                errors: ""
            };

        case EVALUATION_STORE_COMMENT_SUCCESS:
            comments = Object.assign([], state.current.evaluation.comments);
            comments.filter(c => c.userId !== action.data.userId).push(action.data);
            return {
                ...state,
                errors: "",
                current: {
                    ...state.current,
                    evaluation: {
                        ...state.current.evaluation,
                        comments: comments
                    }
                }
            };

        case EVALUATION_STORE_COMMENT_ERROR:
            return {
                ...state,
                errors: action.error.toString()
            };

        case EVALUATION_STORE_COMMENT_LOCAL:
            comments = Object.assign([], state.current.evaluation.comments);
            const newComments = comments.filter(c => c.userId !== action.userId);
            newComments.push({
                evaluationId: state.current.evaluation.id,
                userId: action.userId,
                comment: action.comment
            });
            return {
                ...state,
                errors: "",
                current: {
                    ...state.current,
                    evaluation: {
                        ...state.current.evaluation,
                        comments: newComments
                    }
                }
            };

        default:
            return state;

    }
};

export default reducer;