import {
    EVALUATION_FINISH_STEP_REQUESTING,
    EVALUATION_GET_REQUESTING,
    EVALUATION_REMOVE_CURRENT_EVALUATION,
    EVALUATION_REMOVE_CURRENT_STEP,
    EVALUATION_SET_CURRENT_REQUESTING,
    EVALUATION_STEP_SET_CURRENT_REQUESTING,
    EVALUATION_STORE_ANSWER_REQUESTING,
    EVALUATION_STORE_COMMENT_LOCAL,
    EVALUATION_STORE_COMMENT_REQUESTING
} from "./constants";

export const getEvaluation = (evaluationId) => {
    return {
        type: EVALUATION_GET_REQUESTING,
        evaluationId: parseInt(evaluationId)
    }
}

export const setCurrentEvaluation = (evaluationId) => {
    return {
        type: EVALUATION_SET_CURRENT_REQUESTING,
        evaluationId: parseInt(evaluationId)
    }
}

export const setCurrentEvaluationStep = (evaluationId, stepId) => {
    return {
        type: EVALUATION_STEP_SET_CURRENT_REQUESTING,
        evaluationId: parseInt(evaluationId),
        stepId: parseInt(stepId)
    }
}

export const removeCurrentStep = () => {
    return {
        type: EVALUATION_REMOVE_CURRENT_STEP
    }
}

export const removeCurrentEvaluation = () => {
    return {
        type: EVALUATION_REMOVE_CURRENT_EVALUATION
    }
}

export const saveAnswerToServer = (questionId, stepId, value, info="") => {
    return {
        type: EVALUATION_STORE_ANSWER_REQUESTING,
        stepId: parseInt(stepId),
        questionId: parseInt(questionId),
        value: parseInt(value),
        info: ""+info
    }
};

export const finishAnsweringStep = (stepId) => {
    return {
        type: EVALUATION_FINISH_STEP_REQUESTING,
        stepId: parseInt(stepId)
    }
};

export const onStoreComment = (evaluationId, comment) => {
    return {
        type: EVALUATION_STORE_COMMENT_REQUESTING,
        evaluationId: parseInt(evaluationId),
        comment: comment
    }
}

export const onStoreCommentLocal = (userId, comment) => {
    return {
        type: EVALUATION_STORE_COMMENT_LOCAL,
        userId: parseInt(userId),
        comment: comment
    }
}
