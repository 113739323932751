import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody, MDBCardGroup,
    MDBCardImage,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBContainer, MDBMask,
    MDBRow, MDBView
} from "mdbreact";

const _ = require('underscore');

const CompaniesEvaluation = ({companiesEvaluation, onGotoCompaniesEvaluation}) => {
    console.log("Companies evaluation", companiesEvaluation);
    return <MDBCard key={companiesEvaluation.id} className="md-4 mt-4">
        <h2 className="text-center mt-3">{companiesEvaluation.case.title}</h2>
        <MDBCardGroup>
            <MDBContainer>

                <MDBRow className="mb-4">
                    <MDBCol lg="5">
                        <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                            <img
                                className="img-fluid"
                                src="/images/bolge-istock.jpg"
                                alt=""
                            />
                            <a href="#!">
                                <MDBMask overlay="white-slight"/>
                            </a>
                        </MDBView>
                    </MDBCol>
                    <MDBCol lg="7">

                        <h3 className="font-weight-bold mb-3 p-0">
                            <strong>{companiesEvaluation.case.steps[0].name}</strong>
                        </h3>
                        {companiesEvaluation.case.steps.map((step, index) => {
                            return <div key={index}>
                                {step.evaluation.title}
                            </div>
                        })}
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <div className="text-center">
                            <MDBBtn className="mt-2 mb-4" onClick={() => onGotoCompaniesEvaluation(companiesEvaluation)}
                                    color="blue">
                                Gå til
                            </MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBCardGroup>
    </MDBCard>
}

const Step = ({step, onGotoEvaluation, onGotoResult}) => {
    return (
        <MDBCard key={step.id} className="px-0 mb-4 mr-4">
            <MDBCardImage className="img-fluid" src={step.evaluation.image ? step.evaluation.image : ""} waves
                          alt="Bilde til steg"/>
            <MDBCardBody>
                <MDBCardTitle className="text-center">{step.name}</MDBCardTitle>
                <h3 className="text-center">{step.evaluation.title}</h3>
                <MDBCardText className="text-center">
                    {step.evaluation.ingress}
                </MDBCardText>
                <div className="text-center">
                    <MDBBtn color="blue" onClick={() => onGotoEvaluation(step.evaluation.id)}>Start
                        Besvarelse</MDBBtn>
                    <MDBBtn color="green" onClick={() => onGotoResult(step.evaluation.id)}>Se resultat</MDBBtn>
                </div>
            </MDBCardBody>
        </MDBCard>
    )
};

const Cases = ({caseObj, onGotoEvaluation, onGotoResult}) => {
    return (
        <MDBCard key={caseObj.id} className="md-4 mt-4">
            <h2 className="text-center mt-3">{caseObj.title}</h2>
            <MDBCardGroup>
                {caseObj.steps.map((step, index) => {
                    return (
                        <MDBCol key={index} className="col-md-4">
                            <Step
                                step={step}
                                onGotoEvaluation={onGotoEvaluation}
                                onGotoResult={onGotoResult}
                            />
                        </MDBCol>
                    )
                })}
            </MDBCardGroup>
        </MDBCard>
    )
}

const Dashboard = ({cases, companiesEvaluation, history}) => {

    console.log("Companies Evaluation", companiesEvaluation);
    const onGotoEvaluation = (evaluationId) => {
        history.push("/evaluation/" + evaluationId);
    }

    const onGotoResult = (evaluationId) => {
        history.push("/results/" + evaluationId);
    }

    const onGotoCompaniesEvaluation = (companiesEvaluation) => {
        history.push("companies_evaluation/" + companiesEvaluation.id);
    }

    return (
        <MDBContainer>
            <MDBRow className="col-md-12">
                <div className="text-center col-md-12">
                    <h1 className="mt-2">Utestående saker</h1>
                </div>

                {!_.isEmpty(cases) && cases.map((caseObj, index) => {
                    const cEval = companiesEvaluation.companiesEvaluations.find(
                        ce => ce.case.id === caseObj.id);
                    return (
                        <MDBCol key={index} className="col-md-12">
                            {cEval ?
                                <CompaniesEvaluation
                                    companiesEvaluation={cEval}
                                    onGotoCompaniesEvaluation={onGotoCompaniesEvaluation}
                                /> :
                                <Cases caseObj={caseObj} onGotoResult={onGotoResult}
                                       onGotoEvaluation={onGotoEvaluation}/>
                            }
                        </MDBCol>
                    )
                })}
            </MDBRow>
        </MDBContainer>
    )
}

const mapStateToProps = state => ({
    cases: state.cases.cases,
    companiesEvaluation: state.companiesEvaluation
});

const connected = connect(mapStateToProps)(Dashboard);

export default withRouter(connected);
