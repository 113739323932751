import {
    ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_ERROR,
    ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_REQUESTING,
    ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_SUCCESS,
    ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_ERROR, ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_REQUESTING,
    ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_SUCCESS,
    ADMIN_CREATE_USER_ERROR,
    ADMIN_CREATE_USER_REQUESTING,
    ADMIN_CREATE_USER_SUCCESS,
    ADMIN_FETCH_USERS_REQUESTING,
    ADMIN_UPDATE_USER_ERROR,
    ADMIN_UPDATE_USER_REQUESTING,
    ADMIN_UPDATE_USER_SUCCESS,
    USER_FETCH_ERROR,
    USER_FETCH_REQUESTING,
    USER_FETCH_SUCCESS
} from "./constants";
import {call, put, all, takeEvery} from 'redux-saga/effects';
import {API_BASE_URL} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import {informationToFlat} from "../../modules/information/information";
import {MDBIcon, toast} from 'mdbreact';
import React from "react";
import {showError} from "../../common/lib/show-error";

function apiFetchUser(id) {
    return Fetch(API_BASE_URL+"/users/"+id, 'GET', {});
}

function apiUpdateUser(id, data) {
    return Fetch(API_BASE_URL+"/users/"+id, 'PUT', data);
}

function apiCreateUser(data) {
    return Fetch(API_BASE_URL+"/users/create", 'POST', data);
}

function apiFetchCompaniesEvaluations(finished) {
    return Fetch(API_BASE_URL+"/companies_evaluation/me", 'GET', {finished: finished});
}

function* fetchUser({id}) {
    let response;
    try {
        const result = yield call(apiFetchUser, id);
        response = informationToFlat(result.data);
        yield put({type: USER_FETCH_SUCCESS, data: response});
    } catch(error) {
        yield put({type: USER_FETCH_ERROR});
    }
    return response;
}

function* updateUserWatcher({id, data}) {
    let response;
    try {
        const result = yield call(apiUpdateUser, id, data);
        response = informationToFlat(result.data);
        yield put({type: ADMIN_UPDATE_USER_SUCCESS, data: response});
    } catch(error) {
        yield put({type: ADMIN_UPDATE_USER_ERROR})
    }
    return response;
}

function* createUserWatcher({data}) {
    let response;
    try {
        const result = yield call(apiCreateUser, data);
        response = informationToFlat(result.data);
        toast.info(<span><MDBIcon icon="info-circle" /> Bruker opprettet</span>, {
            closeButton: false
        });
        yield put({type: ADMIN_CREATE_USER_SUCCESS, data: response});
    } catch(error) {
        showError(error);
        yield put({type: ADMIN_CREATE_USER_ERROR})
    }
}

function* fetchCompaniesEvaluationsFinishedWatcher() {
    let response;
    try {
        const result = yield call(apiFetchCompaniesEvaluations, true);
        response = result.data;
        yield put({type: ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_SUCCESS, data: response});
    } catch(error) {
        showError(error);
        yield put({type: ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_ERROR});
    }
}

function* fetchCompaniesEvaluationsOpenWatcher() {
    let response;
    try {
        const result = yield call(apiFetchCompaniesEvaluations, false);
        response = result.data;
        yield put({type: ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_SUCCESS, data: response});
    } catch(error) {
        showError(error);
        yield put({type: ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_ERROR});
    }
}


function* watchAll() {
    yield all([
        takeEvery(USER_FETCH_REQUESTING, fetchUser),
        takeEvery(ADMIN_UPDATE_USER_REQUESTING, updateUserWatcher),
        takeEvery(ADMIN_CREATE_USER_REQUESTING, createUserWatcher),
        takeEvery(ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_REQUESTING, fetchCompaniesEvaluationsFinishedWatcher),
        takeEvery(ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_REQUESTING, fetchCompaniesEvaluationsOpenWatcher)
    ])
}

export default function* IndexSaga() {
    yield all([
        watchAll(),
    ])
}
