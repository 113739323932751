import {handleApiErrors, UnauthorizedError} from "./api-errors";
import 'whatwg-fetch';

function processResponse(response) {
    return new Promise((resolve, reject) => {
        // will resolve or reject depending on status, will pass both "status" and "data" in either case
        let func;
        response.status < 400 ? func = resolve : func = reject;
        if (response.status===401) {
            throw new UnauthorizedError(response.statusText);
        }
        response.json().then(data => func({status: response.status, data: data}));
    });
}

export function Fetch(url, method, params) {
    console.log("Fetcing", url);
    const init = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    };
    if (method !== 'GET' && method !== 'HEAD') {
        init.body = JSON.stringify(params);
    }
    return fetch(url, init)
        .then(processResponse)
        .then(json => json)
        .then(json => json.data)
        .catch(response => {
            // repsonses with status >= 400 get rejected. you can access response.status and response.data here too
            if (response.status === 400) {
                // handle form validation errors, response.data.errors...
            } else if (response.status === 403) {

                throw Error(response.data.message);
            } else {
                throw Error(response.data.message);
            }// etc
        });
}
