import {call, put, all, takeEvery, takeLatest, select} from 'redux-saga/effects';

import {
    API_BASE_URL, AUTH_LOGOUT_SUCCESS,
    USER_GET_ME_SUCCESS
} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import {
    COMPANIES_EVALUATION_FINISH_STEP_ERROR,
    COMPANIES_EVALUATION_FINISH_STEP_REQUESTING, COMPANIES_EVALUATION_FINISH_STEP_SUCCESS,
    COMPANIES_EVALUATION_GET_ERROR,
    COMPANIES_EVALUATION_GET_ME_ERROR,
    COMPANIES_EVALUATION_GET_ME_REQUESTING,
    COMPANIES_EVALUATION_GET_ME_SUCCESS,
    COMPANIES_EVALUATION_GET_REQUESTING,
    COMPANIES_EVALUATION_GET_SUCCESS, COMPANIES_EVALUATION_RELOAD_CURRENT, COMPANIES_EVALUATION_RESET_STATE,
    COMPANIES_EVALUATION_SET_CURRENT_ERROR,
    COMPANIES_EVALUATION_SET_CURRENT_REQUESTING,
    COMPANIES_EVALUATION_SET_CURRENT_SUCCESS,
    COMPANIES_EVALUATION_STORE_ANSWER_ERROR,
    COMPANIES_EVALUATION_STORE_ANSWER_REQUESTING,
    COMPANIES_EVALUATION_STORE_ANSWER_SUCCESS,
} from "./constants";
import {setCurrentCompaniesEvaluation} from "./actions";
import {EVALUATION_FINISH_STEP_SUCCESS} from "../evaluation/constants";


function apiCompaniesEvaluationGet(companiesEvaluationId) {
    return Fetch(API_BASE_URL + "/companies_evaluation/" + companiesEvaluationId, 'GET', {});
}

function apiCompaniesEvaluationAnswer(answer) {
    console.log("Sending answer", answer);
    return Fetch(API_BASE_URL + "/companies_evaluation/answer", 'PUT', answer);
}

function apiCompaniesFinishStep(stepId) {
    return Fetch(API_BASE_URL+"/companies_evaluation/step/finished", "PUT", {stepId: stepId});
}

function apiCompaniesEvaluationMe() {
    return Fetch(API_BASE_URL + "/companies_evaluation/me", "GET", {});
}

function* companiesEvaluationGetMeWatcher() {
    let companiesEvaluations;
    try {
        const result = yield call(apiCompaniesEvaluationMe);
        companiesEvaluations = result.data;
        yield put({type: COMPANIES_EVALUATION_GET_ME_SUCCESS, companiesEvaluations});
    } catch(error) {
        yield put({type: COMPANIES_EVALUATION_GET_ME_ERROR, error});
    }

    return companiesEvaluations;
}

function* companiesEvaluationGetWatcher({companiesEvaluationId}) {
    let evaluation;

    try {
        let result = yield call(apiCompaniesEvaluationGet, companiesEvaluationId);
        evaluation = result.data;
        yield put({type: COMPANIES_EVALUATION_GET_SUCCESS, evaluation});
    } catch (error) {
        yield put({type: COMPANIES_EVALUATION_GET_ERROR, error})
    }
    return evaluation;
}

function* setCurrentCompaniesEvaluationWatcher({companiesEvaluationId}) {
    let companiesEvaluation;

    const state = yield select();
    console.log(state);
//    if (evaluationId in state.evaluation) {
//        evaluation = state.evaluation[evaluationId];
//    } else {
    companiesEvaluation = yield call(companiesEvaluationGetWatcher, {companiesEvaluationId});
//    }
    if (companiesEvaluation) {
        yield put({type: COMPANIES_EVALUATION_SET_CURRENT_SUCCESS, companiesEvaluation});
        return companiesEvaluation;
    } else {
        yield put({type: COMPANIES_EVALUATION_SET_CURRENT_ERROR, error: "unknown"});
    }
    return companiesEvaluation
}

function* setStoreCompaniesAnswerWatcher({stepId, caseStepId, value}) {
    let serverAnswer;

    serverAnswer = yield call(apiCompaniesEvaluationAnswer, {companiesStepId: stepId, caseStepId, value});
    if (serverAnswer) {
        yield put({type: COMPANIES_EVALUATION_STORE_ANSWER_SUCCESS, ...serverAnswer.data})
        return serverAnswer.data;
    } else {
        yield put({type: COMPANIES_EVALUATION_STORE_ANSWER_ERROR, error: "Unknown"})
    }
    return null;
}

function* finishStepWatcher({stepId}) {
    let data;
    try {
        const result = yield call(apiCompaniesFinishStep, stepId);
        data = result.data;
        console.log("Finishing step sagas", data);
        yield put({type: COMPANIES_EVALUATION_FINISH_STEP_SUCCESS, data});
    } catch(error) {
        console.log("Finishing step sagas error");
        yield put({type: COMPANIES_EVALUATION_FINISH_STEP_ERROR, error});
    }
}

function* reloadCurrent() {
    const state = yield select();
    if (state.companiesEvaluation.current.companiesEvaluation)
        yield put(setCurrentCompaniesEvaluation(state.companiesEvaluation.current.companiesEvaluation.id));
}

function* userGetMeWatcher() {
    yield put({type: COMPANIES_EVALUATION_GET_ME_REQUESTING});
}

function* onLogout() {
    yield put({type: COMPANIES_EVALUATION_RESET_STATE});
}

function* watchAll() {
    yield all([
        takeEvery(USER_GET_ME_SUCCESS, userGetMeWatcher),
        takeLatest(COMPANIES_EVALUATION_GET_REQUESTING, companiesEvaluationGetWatcher),
        takeEvery(COMPANIES_EVALUATION_SET_CURRENT_REQUESTING, setCurrentCompaniesEvaluationWatcher),
        takeEvery(COMPANIES_EVALUATION_STORE_ANSWER_REQUESTING, setStoreCompaniesAnswerWatcher),
        takeEvery(COMPANIES_EVALUATION_FINISH_STEP_REQUESTING, finishStepWatcher),
        takeEvery(COMPANIES_EVALUATION_GET_ME_REQUESTING, companiesEvaluationGetMeWatcher),
        takeEvery(COMPANIES_EVALUATION_RELOAD_CURRENT, reloadCurrent),
        takeEvery(AUTH_LOGOUT_SUCCESS, onLogout),
        takeEvery(EVALUATION_FINISH_STEP_SUCCESS, reloadCurrent),
    ]);
}

export default function* IndexSaga() {
    yield all([
        watchAll(),
    ])
}