import React, {useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {
    finishAnsweringStep, onStoreComment, onStoreCommentLocal,
    removeCurrentStep,
    saveAnswerToServer,
    setCurrentEvaluation,
    setCurrentEvaluationStep
} from './actions';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer, MDBInput, MDBNav, MDBNavItem, MDBNavLink,
    MDBRow, MDBTabContent, MDBTable, MDBTableBody, MDBTableHead, MDBTabPane
} from "mdbreact";

const EvaluationStepListRow = ({step, onGoto}) => (
    <MDBBtn color="blue" onClick={() => onGoto(step.id)} className="step">{step.name}</MDBBtn>
);

const EvaluationStepList = ({evaluation, onGoto}) => (
    <React.Fragment>
        <h1 className="col-md-12 text-center">Stegliste</h1>
        {evaluation && evaluation.steps && evaluation.steps.map(step => {
            return <EvaluationStepListRow key={step.id} step={step} onGoto={onGoto}/>
        })}
    </React.Fragment>
);

const Question = ({question, entries, group, step, user, save}) => {
    const saveAnswer = (value) => {
        save(question.id, step.id, value, "");
//        setAnswer(id);
    };

    const answer = step.answers.find(answer => answer.questionId === question.id && answer.userId === user.id);
    return (
        <tr key={question.id}>
            <td>{question.question}</td>
            {entries.map(entry => {
                return <td key={entry.id}  onClick={() => saveAnswer(entry.value)}>
                    <MDBInput checked={answer && answer.value === entry.value ? true : false}
                              onClick={() => saveAnswer(entry.value)}
                              label=" " type="radio" id={group.id + "_" + question.id + "_" + entry.id}></MDBInput>
                </td>
            })}
        </tr>
    )
};

const EvaluationGroup = ({evaluation, group, step, user, save}) => {

    return (
        <MDBCard>
            <MDBRow>
                <MDBCol className="ml-4 mt-4">
                    <h2>{group.title}</h2>
                    {group.ingress}
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className="mt-4">
                    <MDBTable striped>
                        <MDBTableHead color="black" textWhite>
                            <tr>
                                <th className={"col-" + (12 - group.type.entries.length)}>Spørsmål</th>
                                {group.type.entries.map(entry =>
                                    <th key={entry.id} className="col-1">{entry.heading}</th>)}
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {group.questions.map(question =>
                                <Question save={save} key={question.id} question={question} entries={group.type.entries}
                                          group={group} step={step} user={user}/>
                            )}
                        </MDBTableBody>
                    </MDBTable>
                </MDBCol>
            </MDBRow>
        </MDBCard>
    )
}

const EvaluationStep = ({evaluation, step, user, save, onFinishedStep, history, onSaveComment, onSaveCommentLocal}) => {
    const [activeTab, setActiveTab] = useState(0);

    if (evaluation && evaluation.groups) {
        if (activeTab !== evaluation.groups[0].id)
            setActiveTab(evaluation.groups[0].id);
    }

    const comment = evaluation.comments.find(c => c.userId === user.id);

    const allQuestionsAnswered = () => {
        if (!evaluation || !evaluation.groups) return false;
        let questionCount = evaluation.groups.reduce((total, group) => {
            return total + group.questions.length;
        }, 0);
        const userAnswers = step.answers.filter(answer => answer.userId === user.id);
        if (userAnswers.length === questionCount) {
            return true;
        }
        return false;
    };

    const userIsFinished = () => {
        const u = step.users.find(u => u.userId === user.id);
        if (!u) return true;
        return u.finished;
    }

    return (<MDBCol>
        <MDBRow middle>
            <MDBCol className="text-center">
                {step.name}
            </MDBCol>
        </MDBRow>
        <MDBRow end>
            <MDBCol size="3">
                <MDBBtn onClick={history.goBack}>Tilbake</MDBBtn>
                <MDBBtn color={userIsFinished() ? "green" : "blue"}
                        disabled={!allQuestionsAnswered() || userIsFinished()}
                        onClick={() => onFinishedStep(step)}>Ferdig</MDBBtn>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol md="3">
                <MDBNav pills color="primary" className="flex-column">
                    {evaluation && evaluation.groups && evaluation.groups.map(group => {
                        return (
                            <MDBNavItem key={group.id}>
                                <MDBNavLink to="#" className={activeTab === group.id ? "active" : ""}
                                            onClick={() => setActiveTab(group.id)}>
                                    {group.title}
                                </MDBNavLink>
                            </MDBNavItem>
                        )
                    })}
                </MDBNav>
                <div className="pl-2"><MDBInput
                    value={comment ? comment.comment : ""}
                    onChange={(event) => onSaveCommentLocal(user.id, event.target.value)}
                    type="textarea"
                    rows="15"
                    label="Kommentarer"
                    onBlur={(event) => onSaveComment(evaluation, event.target.value)}
                /></div>
            </MDBCol>
            <MDBCol md="9">
                <MDBTabContent activeItem={activeTab} className="p-0">
                    {evaluation && evaluation.groups && evaluation.groups.map(group => {
                        return (
                            <MDBTabPane key={group.id} tabId={group.id} className="p-0">
                                <MDBCardBody>
                                    <EvaluationGroup save={save} user={user} evaluation={evaluation} group={group}
                                                     step={step}/>
                                </MDBCardBody>
                            </MDBTabPane>
                        )
                    })}
                </MDBTabContent>
            </MDBCol>
        </MDBRow>
    </MDBCol>)
};

class Evaluation extends React.Component {

    constructor(props) {
        super(props);
        this.onGoto = this.onGoto.bind(this);
        this.onFinishedStep = this.onFinishedStep.bind(this);
        this.onSaveComment = this.onSaveComment.bind(this);
        this.onSaveCommentLocal = this.onSaveCommentLocal.bind(this);
    }

    onGoto(stepId) {
        const {evaluation} = this.props;
        if (evaluation) {
            this.props.history.push("/evaluation/" + evaluation.id + "/" + stepId);
            this.props.setCurrentEvaluationStep(evaluation.id, stepId);
        }
    }

    onFinishedStep(step) {
        this.props.finishAnsweringStep(step.id);
        this.props.history.goBack();
    }

    onSaveComment(evaluation, comment) {
        this.props.onStoreComment(evaluation.id, comment);
    }

    onSaveCommentLocal(userId, comment) {
        console.log("Saving", userId, comment);
        this.props.onStoreCommentLocal(userId, comment);
    }

    componentDidMount() {
        const evaluationId = this.props.match.params.evaluationId;
        const stepId = this.props.match.params.stepId;
        console.log("Mounting with ", evaluationId, stepId);
        if (evaluationId) {
            if (stepId) {
                console.log("Fetching evaluation step");
                this.props.setCurrentEvaluationStep(evaluationId, stepId);
            } else {
                this.props.removeCurrentStep();
                this.props.setCurrentEvaluation(evaluationId);
            }
        }
    }

    render() {
        const {evaluation, step, user, saveAnswerToServer} = this.props;
        return (
            <MDBContainer fluid>
                <MDBRow middle>
                    <MDBCol>
                        <h1 className="text-center mt-2">{evaluation ? evaluation.title : null}</h1>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                        {step ?
                            <EvaluationStep save={saveAnswerToServer}
                                            evaluation={evaluation}
                                            step={step}
                                            user={user}
                                            onFinishedStep={this.onFinishedStep}
                                            history={this.props.history}
                                            onSaveComment={this.onSaveComment}
                                            onSaveCommentLocal={this.onSaveCommentLocal}
                            /> :
                            <EvaluationStepList evaluation={evaluation} user={user} onGoto={this.onGoto}/>}
                </MDBRow>
            </MDBContainer>
        )
    }
}

const mapStateToProps = state => ({
    evaluation: state.evaluation.current.evaluation,
    step: state.evaluation.current.step,
    user: state.user.user,
});

const connected = connect(mapStateToProps, {
    setCurrentEvaluation,
    setCurrentEvaluationStep,
    removeCurrentStep,
    saveAnswerToServer,
    finishAnsweringStep,
    onStoreComment,
    onStoreCommentLocal,
})(Evaluation);

export default withRouter(connected);