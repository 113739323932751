import { combineReducers } from 'redux';
import user from './components/user/reducer';
import auth from './components/auth/reducer'
import cases from './components/cases/reducer';
import evaluation from './components/evaluation/reducer';
import companiesEvaluation from './components/companies-evaluation/reducer';
import socket from './modules/socket/reducer';
import admin from './components/admin/reducer';

import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
    router: connectRouter(history),
    user: user,
    auth: auth,
    cases: cases,
    evaluation: evaluation,
    companiesEvaluation: companiesEvaluation,
    socket: socket,
    admin: admin,
})
