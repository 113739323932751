import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {MDBBtn, MDBDataTable} from "mdbreact";
import {getUser, getUsersRequest, resetUserInState} from "./actions";
import User from "./User";
import Modal from "react-bootstrap/Modal";
import {API_BASE_URL} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import {showError} from "../../common/lib/show-error";

const _ = require('underscore');

const userTableColumns = [
    {
        label: "Fornavn",
        field: "firstName",
        sort: "asc",
        width: 150
    },
    {
        label: "Etternavn",
        field: "lastName",
        sort: "asc",
        width: 150
    },
    {
        label: "E-post",
        field: "email",
        sort: "asc",
        width: 250
    },
    {
        label: "Roller",
        field: "roles",
        sort: "disabled",
        width: 150
    }
];


const Users = ({}) => {
    const [userId, setUserId] = useState(null);
    const [fixedData, setFixedData] = useState({
        columns: userTableColumns,
        rows: []
    });

    const fetchUsers = async () => {
        try {
            await Fetch(API_BASE_URL + "/admin/users", "GET", {}).then(result => {
                manageServerUserData(result.data);
            })
        } catch (error) {
            showError(error);
        }
    }

    useEffect( () => {
        fetchUsers();
    }, []);

    const manageServerUserData = (data) => {
        let rows = [];
        if (_.isArray(data)) {
            for (let row of data) {
                const id = row.id;
                let r = {
                    firstName: row.firstName,
                    lastName: row.lastName,
                    email: row.email,
                    roles: row.roles.map((role) => role.roleName).join(", "),
                    clickEvent: () => setUserId(row.id)
                };
                rows.push(r);
            }
        }
        setFixedData({...fixedData, rows: rows});
    };

    const addUser = () => {
        setUserId(-1);
    };

    const onFinished = () => {
        setUserId(null);
        fetchUsers();
    }

    return (
        <div>
            <div>
                <MDBDataTable
                    className={"pointer"} striped hover
                    data={fixedData}
                    searchLabel={"Søk"}
                    paginationLabel={["Forrige", "Neste"]}
                    infoLabel={["Viser", "til", "av", "rader"]}
                    entriesLabel="Vis antall rader"
                    entries={5}
                    entriesOptions={[5, 10, 15]}
                />
                <div className="fixed-action-btn button-create">
                    <div onClick={addUser} className="btn-floating btn-lg green">
                        <i className="fas fa-plus"></i>
                    </div>
                </div>

            </div>
            <Modal show={userId!==null} onHide={() => setUserId(null)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Bruker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <User userId={userId} onFinished={onFinished}/>
                </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-around">
                    <MDBBtn onClick={() => setUserId(null)} className="blue-gradient">
                        Lukk
                    </MDBBtn>
                </div>
            </Modal.Footer>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.admin.user.user,
    pathname: state.router.location.pathname,
});

const connected = connect(mapStateToProps, {getUsersRequest, getUser, resetUserInState})(Users);

export default withRouter(connected);
