export const EVALUATION_RESET_STATE = 'EVALUATION_RESET_STATE';

export const EVALUATION_GET_REQUESTING = 'EVALUATION_GET_REQUESTING';
export const EVALUATION_GET_SUCCESS = 'EVALUATION_GET_SUCCESS';
export const EVALUATION_GET_ERROR = 'EVALUATION_GET_ERROR';

export const EVALUATION_SET_CURRENT_REQUESTING = 'EVALUATION_SET_CURRENT_REQUESTING';
export const EVALUATION_SET_CURRENT_SUCCESS = 'EVALUATION_SET_CURRENT_SUCCESS';
export const EVALUATION_SET_CURRENT_ERROR = 'EVALUATION_SET_CURRENT_ERROR';

export const EVALUATION_STEP_SET_CURRENT_REQUESTING = 'EVALUATION_STEP_SET_CURRENT_REQUESTING';
export const EVALUATION_STEP_SET_CURRENT_SUCCESS = 'EVALUATION_STEP_SET_CURRENT_SUCCESS';
export const EVALUATION_STEP_SET_CURRENT_ERROR = 'EVALUATION_STEP_SET_CURRENT_ERROR';

export const EVALUATION_REMOVE_CURRENT_EVALUATION = 'EVALUATION_REMOVE_CURRENT_EVALUATION';
export const EVALUATION_REMOVE_CURRENT_STEP = 'EVALUATION_REMOVE_CURRENT_STEP';

export const EVALUATION_STORE_ANSWER_REQUESTING = 'EVALUATION_STORE_ANSWER_REQUESTING';
export const EVALUATION_STORE_ANSWER_SUCCESS = 'EVALUATION_STORE_ANSWER_SUCCESS';
export const EVALUATION_STORE_ANSWER_ERROR = 'EVALUATION_STORE_ANSWER_ERROR';

export const EVALUATION_FINISH_STEP_REQUESTING = 'EVALUATION_FINISH_STEP_REQUESTING';
export const EVALUATION_FINISH_STEP_SUCCESS = 'EVALUATION_FINISH_STEP_SUCCESS';
export const EVALUATION_FINISH_STEP_ERROR = 'EVALUATION_FINISH_STEP_ERROR';

export const EVALUATION_STORE_COMMENT_REQUESTING = 'EVALUATION_STORE_COMMENT_REQUESTING';
export const EVALUATION_STORE_COMMENT_SUCCESS = 'EVALUATION_STORE_COMMENT_SUCCESS';
export const EVALUATION_STORE_COMMENT_ERROR = 'EVALUATION_STORE_COMMENT_ERROR';

export const EVALUATION_STORE_COMMENT_LOCAL = 'EVALUATION_STORE_COMMENT_LOCAL';