export const COMPANIES_EVALUATION_RESET_STATE = 'COMPANIES_EVALUATION_RESET_STATE';

export const COMPANIES_EVALUATION_GET_REQUESTING = 'COMPANIES_EVALUATION_GET_REQUESTING';
export const COMPANIES_EVALUATION_GET_SUCCESS = 'COMPANIES_EVALUATION_GET_SUCCESS';
export const COMPANIES_EVALUATION_GET_ERROR = 'COMPANIES_EVALUATION_GET_ERROR';

export const COMPANIES_EVALUATION_SET_CURRENT_REQUESTING = 'COMPANIES_EVALUATION_SET_CURRENT_REQUESTING';
export const COMPANIES_EVALUATION_SET_CURRENT_SUCCESS = 'COMPANIES_EVALUATION_SET_CURRENT_SUCCESS';
export const COMPANIES_EVALUATION_SET_CURRENT_ERROR = 'COMPANIES_EVALUATION_SET_CURRENT_ERROR';

export const COMPANIES_EVALUATION_REMOVE_CURRENT_EVALUATION = 'COMPANIES_EVALUATION_REMOVE_CURRENT_EVALUATION';

export const COMPANIES_EVALUATION_UPDATE_LOCAL_ANSWER = 'COMPANIES_EVALUATION_UPDATE_LOCAL_ANSWER';
export const COMPANIES_EVALUATION_STORE_ANSWER_REQUESTING = 'COMPANIES_EVALUATION_STORE_ANSWER_REQUESTING';
export const COMPANIES_EVALUATION_STORE_ANSWER_SUCCESS = 'COMPANIES_EVALUATION_STORE_ANSWER_SUCCESS';
export const COMPANIES_EVALUATION_STORE_ANSWER_ERROR = 'COMPANIES_EVALUATION_STORE_ANSWER_ERROR';

export const COMPANIES_EVALUATION_FINISH_STEP_REQUESTING = 'COMPANIES_EVALUATION_FINISH_STEP_REQUESTING';
export const COMPANIES_EVALUATION_FINISH_STEP_SUCCESS = 'COMPANIES_EVALUATION_FINISH_STEP_SUCCESS';
export const COMPANIES_EVALUATION_FINISH_STEP_ERROR = 'COMPANIES_EVALUATION_FINISH_STEP_ERROR';

export const COMPANIES_EVALUATION_GET_ME_REQUESTING = 'COMPANIES_EVALUATION_GET_ME_REQUESTING';
export const COMPANIES_EVALUATION_GET_ME_SUCCESS = 'COMPANIES_EVALUATION_GET_ME_SUCCESS';
export const COMPANIES_EVALUATION_GET_ME_ERROR = 'COMPANIES_EVALUATION_GET_ME_ERROR';

export const COMPANIES_EVALUATION_RELOAD_CURRENT = 'COMPANIES_EVALUATION_RELOAD_CURRENT';
