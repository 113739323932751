import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import './User.css';
import {doCreateUser, doUpdateUser, getUser} from './actions';
import useForm from '../../modules/forms/useForm';
import GITextInput from "../../modules/information/GITextInput";
import {informationToObject} from "../../modules/information/information";
import GITextArea from "../../modules/information/GITextArea";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {API_BASE_URL} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import {showError, showInfo} from "../../common/lib/show-error";

const _ = require("underscore");

const User = ({userId, onFinished}) => {
    const [usernameChecked, setUsernameChecked] = useState([]);
    const [emailChecked, setEmailChecked] = useState([]);
    const [user, setUser] = useState(null);
    const [roles, setRoles] = useState([]);

    const fetchUser = async () => {
        try {
            await Fetch(API_BASE_URL + "/admin/user/" + userId, "GET", {}).then(result => {
                setUser(result.data);
                setRoles(result.data.roles.map(role => role.roleName));
            })
        } catch (error) {
            showError(error);
        }
    }

    useEffect( () => {
        if (userId === -1 || userId===null ||userId===undefined) return;
        fetchUser();
    }, [userId]);

    const doSubmit = () => {

    };

    const doValidate = (values) => {
        let errors = {};
        if (!values) return errors;
        if (!values.email) {
            errors.email = 'E-post adresse er påkrevd';
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            errors.email = 'E-post adressen er ikke gyldig';
        }
        if (!values.username || values.username.length < 0) {
            errors.username = 'Trenger brukernavn';
        }
        if (!values.firstName || values.firstName.length < 1) {
            errors.firstName = "Trenger fornavn";
        }
        if (!values.lastName || values.lastName.length < 1) {
            errors.lastName = "Trenger etternavn";
        }

        const user = usernameChecked.filter(u => u.username === values.username ? u.exists : null);
        if (user.length > 0) {
            if (changed.username && user[0].username === values.username && user[0].exists) {
                errors.username = "Brukernavnet finnes fra før";
            }
        }

        const email = emailChecked.filter(u => u.email === values.email ? u.exists : null);
        if (email.length > 0) {
            if (changed.email && email[0].email === values.email && email[0].exists) {
                errors.email = "Epost-adressen finnes fra før";
            }
        }
        return errors;
    };

    const checkUniqueUsername = async (username) => {
        const cache = usernameChecked.filter(u => u.username === username ? u.exists : null);
        if (cache.length === 0) {
            try {
                await Fetch(API_BASE_URL + "/admin/checkUsername", 'POST', {username: username}).then(result => {
                    setUsernameChecked([...usernameChecked, result.data]);
                })
            } catch (error) {
            }
        }
    }

    const checkUniqueEmail = async (email) => {
        const cache = usernameChecked.filter(u => u.email === email ? u.exists : null);
        if (cache.length === 0) {
            try {
                await Fetch(API_BASE_URL + "/admin/checkEmail", 'POST', {email: email}).then(result => {
                    setEmailChecked([...emailChecked, result.data]);
                })
            } catch (error) {
            }
        }
    }

    useEffect(() => {
        doValidation();
    }, [usernameChecked, emailChecked]);


    useEffect(() => {
        if (userId !== -1) {
            getUser(userId);
        }
    }, [userId]);

    const saveUser = async () => {
        try {
            const data = {
                id: userId,
                username: values.username,
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName
            };
            await Fetch(API_BASE_URL + "/admin/user/update", "POST", data).then(result => {
                if (userId===-1) {
                    showInfo("Brukeren er opprettet");
                } else {
                    showInfo("Brukeren er Oppdatert");
                }
                onFinished();
            })
        } catch (error) {
            showError(error);
        }
    };


    let initValues = userId !== -1 ? informationToObject(user) : {};
    const {values, errors, isChanged, changed, handleChange, handleSubmit, doValidation} = useForm(initValues, saveUser, doValidate);

    const handleUsernameChange = (event) => {
        handleChange(event);
        checkUniqueUsername(event.target.value);
    };

    const handleEmailChange = (event) => {
        handleChange(event);
        checkUniqueEmail(event.target.value);
    };


    return (
        <Form>
            <Form.Row>
                <Form.Group as={Col} controlId="username">
                    <GITextInput
                        label={"Brukernavn"}
                        name={"username"}
                        value={values && values.username ? values.username : ''}
                        valid={errors.username}
                        handleChange={handleUsernameChange}
                        onError={errors.username}
                    />
                </Form.Group>
                <Form.Group as={Col} controlId="email">
                    <GITextInput
                        label={"E-post"}
                        name={"email"}
                        value={values && values.email ? values.email : ''}
                        valid={errors.email}
                        handleChange={handleEmailChange}
                        onError={errors.email}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId={"firstName"}>
                    <GITextInput
                        label={"Fornavn"}
                        name={"firstName"}
                        value={values && values.firstName ? values.firstName : ''}
                        valid={errors.firstName}
                        handleChange={handleChange}
                        onError={errors.firstName}
                    />
                </Form.Group>
                <Form.Group as={Col} controlId={"lastName"}>
                    <GITextInput
                        label={"Etternavn"}
                        name={"lastName"}
                        value={values && values.lastName ? values.lastName : ''}
                        valid={errors.lastName}
                        handleChange={handleChange}
                        onError={errors.lastName}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId={"roles"}>
                    <Form.Label column sm="2" className={"pl-0"}>
                        Roller
                    </Form.Label>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check inline label="admin" type={"checkbox"} id={`roles-admin`} checked={roles.includes("ADMIN")} />
                    </div>
                </Form.Group>
            </Form.Row>
            <MDBBtn onClick={handleSubmit} color={"success"}
                    disabled={!isChanged || !_.isEmpty(errors)}>{userId !== -1 ? "Oppdater" : "Opprett"}</MDBBtn>

        </Form>
    );
};

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {})(User);

export default withRouter(connected);
