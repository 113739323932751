import {CASES_GET_ME_ERROR, CASES_GET_ME_REQUESTING, CASES_GET_ME_SUCCESS, CASES_RESET_STATE} from "./constants";

const initialState = {
    cases: [],
    errors: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CASES_RESET_STATE:
            return {
                ...initialState
            }
        case CASES_GET_ME_REQUESTING:
            return {
                ...state
            };

        case CASES_GET_ME_SUCCESS:
            return {
                cases: action.cases,
                errors: ""
            };

        case CASES_GET_ME_ERROR:
            return {
                cases: [],
                errors: action.error.toString()
            };

        default:
            return state;
    }
}

export default reducer;