import React, {Component} from 'react';
import './App.css';
import Dashboard from "./components/dashboard";
import {Route, withRouter} from "react-router-dom";
import Login from "./components/auth/login/AuthLogin";
import {PrivateRoute} from "./components/PrivateRoute";
import Header from "./components/header/Header";
import {Switch} from "react-router";
import Evaluation from "./components/evaluation";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {loggedInRequest} from "./actions";
import Results from "./components/results";
import CompaniesEvaluationDashboard from "./components/companies-evaluation/CompaniesEvaluationDashboard";
import Admin from "./components/admin";
import {ToastContainer} from 'mdbreact';
import ResetPassword from "./components/auth/login/ResetPassword";

class App extends Component {
    constructor(props) {
        super(props);

        const user = localStorage.getItem("auth");
        if (user) {
            this.props.loggedInRequest()
        }
        this.state = {
            toggle: false,
            windowWidth: 0,
            currentPage: '',
            sideNavToggled: false,
            breakWidth: 1400
        };
    }

    render() {

        return (
            <>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={5000}
                />
                <Switch>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/resetPassword/:token" exact component={ResetPassword}/>
                    <React.Fragment>
                        <PrivateRoute path="/" component={Header}/>
                        <PrivateRoute path="/dashboard" exact component={Dashboard}/>
                        <PrivateRoute path="/evaluation/:evaluationId?/:stepId?" component={Evaluation}/>
                        <PrivateRoute path="/results/:evaluationId?/:stepId?" component={Results}/>
                        <PrivateRoute path="/results" exact component={Results}/>
                        <PrivateRoute path="/companies_evaluation/:companiesEvaluationId" exact
                                      component={CompaniesEvaluationDashboard}/>
                        <PrivateRoute path="/admin" component={Admin}/>
                    </React.Fragment>
                </Switch>
            </>
        );
    }
}

App.propTypes = {
    loggedInRequest: PropTypes.func
}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {loggedInRequest})(App);

export default withRouter(connected);
