import React, {useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    MDBBtn,
    MDBCard, MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBRow,
    MDBTabContent, MDBTable, MDBTableBody, MDBTableHead,
    MDBTabPane
} from "mdbreact";
import {
    removeCurrentEvaluation,
    removeCurrentStep,
    setCurrentEvaluation,
    setCurrentEvaluationStep
} from "../evaluation/actions";
import './Result.scss';

export const getMaxSumQuestion = (evaluation, step, question, group, user = null) => {
    const max = group.type.sum;
    const count = step.users.filter(u => u.access.includes('W')).length;
    return max * (user ? 1 : count);
};

export const getAnswerSumQuestion = (evaluation, step, question, user = null) => {
    const answers = step.answers.filter(answer =>
        answer.questionId === question.id &&
        (!user || user.id === answer.userId)
    );
    let sum = 0;
    answers.map(answer => sum += answer.value);
    return sum;
};

export const getStepGroupMaxSum = (evaluation, step, group, user = null) => {
    const result = group.questions.reduce((total, question) => {
        return total + getMaxSumQuestion(evaluation, step, question, group, user);
    }, 0);
    return result;
};

export const getStepGroupAnswerSum = (evaluation, step, group, user = null) => {
    const result = group.questions.reduce((total, question) => {
        return total + getAnswerSumQuestion(evaluation, step, question, user);
    }, 0);
    return result;
};

export const getStepMaxSum = (evaluation, step, user = null) => {
    const result = evaluation.groups.reduce((total, group) => {
        return total + getStepGroupMaxSum(evaluation, step, group, user);
    }, 0)
    return result;
};

export const getStepAnswerSum = (evaluation, step, user = null) => {
    const result = evaluation.groups.reduce((total, group) => {
        return total + getStepGroupAnswerSum(evaluation, step, group, user);
    }, 0);
    return result;
};

export const getGroupMaxSum = (evaluation, group, user = null) => {
    const maxSum = evaluation.steps.reduce((total, step) => {
        return total + step.isSumStep ? getStepGroupMaxSum(evaluation, step, group, user) : 0;
    }, 0);
    return maxSum;
};

export const getEvaluationMaxSum = (evaluation) => {
    const maxSum = evaluation.groups.reduce((total, group) => {
        return total + getGroupMaxSum(evaluation, group);
    }, 0);
    return maxSum;
};

const Question = ({evaluation, question, group, user, isInactive, gotoEvaluationStep}) => {
    let maxSum = 0;
    let answerMaxSum = 0;
    let maxSumUser = 0;
    let answerMaxSumUser = 0;

    return (
        <tr key={question.id}>
            <td>{question.question}</td>
            {evaluation.steps.map(step => {
                const writeAccess = step.users.find(u => u.userId === user.id && u.access.includes("W"));
                const sum = getMaxSumQuestion(evaluation, step, question, group);
                const answerSum = getAnswerSumQuestion(evaluation, step, question);
                const sumUser = getMaxSumQuestion(evaluation, step, question, group, user);
                const answerSumUser = getAnswerSumQuestion(evaluation, step, question, user);
                maxSum += isInactive(step) ? 0 : sum;
                answerMaxSum += isInactive(step) ? 0 : answerSum;
                maxSumUser += isInactive(step) || !writeAccess ? 0 : sumUser;
                answerMaxSumUser += isInactive(step) || !writeAccess ? 0 : answerSumUser;

                return (
                    <td key={step.id} onClick={() => {
                        if (writeAccess && step.finished)
                            gotoEvaluationStep(evaluation, step)
                    }}>
                        <span>{(100 * answerSum) / sum}% </span>
                        (<span className="green-text">{(100 * answerSumUser) / sumUser}%</span>)
                    </td>
                )
            })}
            <td>
                <span>{maxSum === 0 ? "" : (100 * answerMaxSum) / maxSum}% </span>
                (<span className="green-text">{maxSumUser === 0 ? "" : (100 * answerMaxSumUser) / maxSumUser}%</span>)
            </td>
        </tr>
    )
};

const ResultGroup = ({evaluation, group, user, isInactive, toggleActive, gotoEvaluationStep}) => {
    let maxSum = 0;
    let answerSum = 0;
    let maxSumUser = 0;
    let answerSumUser = 0;

    return (
        <MDBCard>
            <MDBRow middle>
                <MDBCol>
                    <h2>{group.title}</h2>
                    {group.ingress}
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md="12">
                    <MDBTable striped>
                        <MDBTableHead color="black" textWhite>
                            <tr>
                                <th>Spørsmål</th>
                                {evaluation.steps.map(step =>
                                    <th className={"pointer grey " + (isInactive(step) ? "lighten-1" : "")}
                                        key={step.id} onClick={() => toggleActive(step)}>{step.name}</th>)}
                                <th key="sum" className="grey">Sum</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {group.questions.map(question =>
                                <Question key={question.id}
                                          evaluation={evaluation}
                                          question={question}
                                          group={group}
                                          user={user}
                                          isInactive={isInactive}
                                          gotoEvaluationStep={gotoEvaluationStep}
                                />
                            )}
                            <tr>
                                <td>Sum</td>
                                {evaluation.steps.map(step => {
                                    const max = getStepGroupMaxSum(evaluation, step, group);
                                    const answer = getStepGroupAnswerSum(evaluation, step, group);
                                    const maxUser = getStepGroupMaxSum(evaluation, step, group, user);
                                    const answerUser = getStepGroupAnswerSum(evaluation, step, group, user);
                                    maxSum += isInactive(step) ? 0 : max;
                                    answerSum += isInactive(step) ? 0 : answer;
                                    maxSumUser += isInactive(step) || !step.users.find(u => u.userId === user.id && u.access.includes("W")) ? 0 : maxUser;
                                    answerSumUser += isInactive(step) || !step.users.find(u => u.userId === user.id && u.access.includes("W")) ? 0 : answerUser;
                                    return <td key={step.id}>
                                        <span>{(100 * answer) / max}% </span>
                                        (<span className="green-text">{(100 * answerUser) / maxUser}%</span>)
                                    </td>
                                })}
                                <td>
                                    <span>{maxSum === 0 ? "" : (100 * answerSum) / maxSum}% </span>
                                    (<span
                                    className="green-text">{maxSumUser === 0 ? "" : (100 * answerSumUser) / maxSumUser}%</span>)

                                </td>
                            </tr>
                        </MDBTableBody>
                    </MDBTable>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className="ml-3 pb-3 green-text">
                    Grønne tall er dine svar
                </MDBCol>
            </MDBRow>
        </MDBCard>
    )
}

const Result = ({evaluation, user, gotoEvaluationStep}) => {
    const [activeTab, setActiveTab] = useState(evaluation.groups[0].id);
    const [inactiveSteps, setInactiveSteps] = useState(
        evaluation.steps.filter(step => !step.isSumStep)
    );

    const isInactiveStep = (step) => {
        return inactiveSteps.find(s => s.id === step.id) !== undefined
    }

    const toggleInactiveStep = (step) => {
        let steps = Object.assign([], inactiveSteps);
        if (isInactiveStep(step)) {
            setInactiveSteps(steps.filter(s => s.id !== step.id));
        } else {
            steps.push(step);
            setInactiveSteps(steps);
        }
    };

    return (<React.Fragment>
        <MDBRow>
            <MDBCol md="2">
                <MDBNav pills color="primary" className="flex-column">
                    {evaluation.groups.map(group => {
                        return (
                            <MDBNavItem key={group.id}>
                                <MDBNavLink to="#" className={activeTab === group.id ? "active" : ""}
                                            onClick={() => setActiveTab(group.id)}>
                                    {group.title}
                                </MDBNavLink>
                            </MDBNavItem>
                        )
                    })}
                </MDBNav>
            </MDBCol>
            <MDBCol md="10">
                <MDBTabContent activeItem={activeTab} className="p-0">
                    {evaluation.groups.map(group => {
                        return (
                            <MDBTabPane key={group.id} tabId={group.id} className="p-0">
                                <MDBCardBody>
                                    <ResultGroup isInactive={isInactiveStep}
                                                 toggleActive={toggleInactiveStep}
                                                 user={user}
                                                 evaluation={evaluation}
                                                 group={group}
                                                 gotoEvaluationStep={gotoEvaluationStep}
                                    />
                                </MDBCardBody>
                            </MDBTabPane>
                        )
                    })}
                </MDBTabContent>
            </MDBCol>
        </MDBRow>
    </React.Fragment>)
};


class Results extends React.Component {

    constructor(props) {
        super(props);

        this.gotoEvaluationStep = this.gotoEvaluationStep.bind(this);
    }

    gotoEvaluationStep(evaluation, step) {
        this.props.history.push("/evaluation/" + evaluation.id + "/" + step.id);
    }

    componentDidMount() {
        const evaluationId = this.props.match.params.evaluationId;
        if (evaluationId) {
            this.props.removeCurrentStep();
            this.props.removeCurrentEvaluation();
            this.props.setCurrentEvaluation(evaluationId);
        }
    }

    render() {
        const {evaluation, user} = this.props;
        return (
            <MDBContainer fluid>
                <MDBRow>
                    <MDBCol>
                        <h1 className="text-center mt-2">{evaluation ? evaluation.title : null}</h1>
                    </MDBCol>
                </MDBRow>
                <MDBRow end>
                    <MDBCol size="2">
                        <MDBBtn className="mr-5" onClick={this.props.history.goBack}>Tilbake</MDBBtn>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol>
                        {evaluation ? <Result gotoEvaluationStep={this.gotoEvaluationStep} evaluation={evaluation}
                                              user={user}/> : null}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

const mapStateToProps = state => ({
    evaluation: state.evaluation.current.evaluation,
    user: state.user.user,
});

const connected = connect(mapStateToProps, {
    setCurrentEvaluation,
    setCurrentEvaluationStep,
    removeCurrentStep,
    removeCurrentEvaluation,
})(Results);

export default withRouter(connected);