import {MDBDataTable} from "mdbreact";
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
    getCompaniesEvaluations,
    resetAdminCompaniesEvaluationInState,
    setAdminCompaniesEvaluationInState
} from "../actions";

const _ = require('underscore');

const CompaniesEvaluations = ({
                                  companiesEvaluation,
                                  companiesEvaluationsFinishedData,
                                  companiesEvaluationsOpenData,
                                  getCompaniesEvaluations,
                                  resetAdminCompaniesEvaluationInState,
                                  setAdminCompaniesEvaluationInState,
                              }) => {
    const [companiesEvaluationId, setCompaniesEvaluationId] = useState(null);

    const columns = [
        {
            label: "Tittel",
            field: "title",
            sort: "asc",
            width: 150
        }
    ];

    let fixedData = {
        columns: columns,
        rows: []
    };

    const manageServerData = () => {
        let rows = [];
        if (_.isArray(companiesEvaluationsOpenData)) {
            for (let row of companiesEvaluationsOpenData) {
                let r = {
                    title: row.case.title,
                    clickEvent: () => clickRow(row.id)
                }
                rows.push(r);
            }
        }
        fixedData.rows = rows;
    };

    manageServerData();

    useEffect(() => {
        if (!companiesEvaluationsOpenData) {
            getCompaniesEvaluations();
        }
    });

    useEffect(() => {
        if (companiesEvaluationId === -1) {
            resetAdminCompaniesEvaluationInState();
        } else if (companiesEvaluationId !== null) {
            setAdminCompaniesEvaluationInState(findEvaluation(companiesEvaluationId));
        } else {
            resetAdminCompaniesEvaluationInState();
        }
    }, [companiesEvaluationId]);

    const findEvaluation = (id) => {
        if (_.isArray(companiesEvaluationsOpenData)) {
            for (let row of companiesEvaluationsOpenData) {
                if (row.id === id)
                    return row;
            }
        }
        return null;
    }

    const clickRow = (id) => {
        setCompaniesEvaluationId(id);
    }

    const addCompaniesEvaluation = () => {
        setCompaniesEvaluationId(-1);
    };

    return (
        <div>
            {companiesEvaluation === null && companiesEvaluationId !== -1 ? (
                <div>
                    <MDBDataTable
                        className={"pointer"} striped hover
                        data={fixedData}
                        searchLabel={"Søk"}
                        paginationLabel={["Forrige", "Neste"]}
                        infoLabel={["Viser", "til", "av", "rader"]}
                        entriesLabel="Vis antall rader"
                        entries={5}
                        entriesOptions={[5, 10, 15]}
                    />
                    <div className="fixed-action-btn button-create">
                        <div onClick={addCompaniesEvaluation} className="btn-floating btn-lg green">
                            <i className="fas fa-plus"></i>
                        </div>
                    </div>

                </div>
            ) : (
                <div>Evaluering</div>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    companiesEvaluation: state.admin.companiesEvaluation.companiesEvaluation,
    companiesEvaluationsFinishedData: state.admin.companiesEvaluations.finished,
    companiesEvaluationsOpenData: state.admin.companiesEvaluations.open,
    companiesEvaluationUpdated: state.admin.companiesEvaluation.isUpdated
});

const connected = connect(mapStateToProps, {
    getCompaniesEvaluations,
    resetAdminCompaniesEvaluationInState,
    setAdminCompaniesEvaluationInState
})(CompaniesEvaluations);

export default withRouter(connected);
