import {eventChannel} from 'redux-saga';
import {call, take, takeEvery, put, all, race, cancelled, delay, fork} from 'redux-saga/effects';

import io from "socket.io-client";
import {
    SOCKET_CHANNEL_OFF,
    SOCKET_CHANNEL_ON,
    SOCKET_SERVER_OFF,
    SOCKET_SERVER_ON,
    SOCKET_START_CHANNEL, SOCKET_STOP_CHANNEL
} from "./constants";
import {AUTH_LOGOUT_SUCCESS} from "../../components/auth/constants";
import {USER_GET_ME_SUCCESS} from "../../components/user/constants";

const socketServerURL = 'https://blueplanet.grensesnitt.no/';
//const socketServerURL = 'http://localhost:3020/';

let socket;
const connect = () => {
    console.log("Connecting to server");
    socket = io(socketServerURL);
    return new Promise((resolve) => {
        socket.on('connect', () => {
            console.log("Connected");
            resolve(socket);
        });
    });
};

const disconnect = () => {
    return new Promise((resolve) => {
        socket.on('disconnect', () => {
            console.log("Disconnecting");
            resolve(socket);
        });
    });
};

const reconnect = () => {
    return new Promise((resolve) => {
        socket.on('reconnect', () => {
            console.log("Reconnecting");
            resolve(socket);
        });
    });
};

// This is how channel is created
const createSocketChannel = socket => eventChannel((emit) => {
    const handler = (data) => {
        emit(data);
    };
    socket.on('event', handler);
    return () => {
        socket.off('event', handler);
    };
});

// connection monitoring sagas
const listenDisconnectSaga = function* () {
    while (true) {
        yield call(disconnect);
        yield put({type: SOCKET_SERVER_OFF});
    }
};

const listenConnectSaga = function* () {
    while (true) {
        yield call(reconnect);
        yield put({type: SOCKET_SERVER_ON});
    }
};

// Saga to switch on channel.
const listenServerSaga = function* () {
    try {
        yield put({type: SOCKET_CHANNEL_ON});
        const {socket, timeout} = yield race({
            socket: call(connect),
            timeout: delay(5000),
        });
        if (timeout) {
            yield put({type: SOCKET_SERVER_OFF});
        }
        const socketChannel = yield call(createSocketChannel, socket);
        yield fork(listenDisconnectSaga);
        yield fork(listenConnectSaga);
        yield put({type: SOCKET_SERVER_ON});

        while (true) {
            const payload = yield take(socketChannel);
            console.log("Got payload", payload);
            yield put({type: payload.type});
        }
    } catch (error) {
        console.log(error);
    } finally {
        if (yield cancelled()) {
            socket.disconnect(true);
            yield put({type: SOCKET_CHANNEL_OFF});
        }
    }
};

// saga listens for start and stop actions
const startStopChannel = function* () {
    while (true) {
        yield take(SOCKET_START_CHANNEL);
        yield race({
            task: call(listenServerSaga),
            cancel: take(SOCKET_STOP_CHANNEL),
        });
    }
};

const connectOnLogin = function* () {
    yield put({type: SOCKET_START_CHANNEL});
}

const disconnectOnLogout = function* () {
    yield put({type: SOCKET_STOP_CHANNEL});
}

function* watchAll() {
    yield all([
        takeEvery(USER_GET_ME_SUCCESS, connectOnLogin),
        takeEvery(AUTH_LOGOUT_SUCCESS, disconnectOnLogout)
    ]);
}

export default function* IndexSaga() {
    yield all([
        watchAll(),
        startStopChannel(),
    ])
}
