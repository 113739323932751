import React from "react";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
    MDBBtn,
    MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBInput,
    MDBRow, MDBTooltip
} from "mdbreact";
import {
    finishCompaniesAnsweringStep,
    onStoreCompaniesEvaluationAnswer,
    onUpdateCompaniesEvaluationAnswer,
    setCurrentCompaniesEvaluation
} from "./actions";
import {getStepAnswerSum, getStepMaxSum} from "../results/Results";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";

const _ = require('underscore');


const StepFinished = ({companiesEvaluation, evaluation, step, user, history}) => {
    const canView = step.users.find(u => u.userId === user.id);
    if (!canView) return <div>Ukjent</div>;
    const hasAnswered = !!step.answers.find(answer => answer.userId === user.id);

    const onGotoResults = (evaluation) => {
        history.push("/results/" + evaluation.id);
    }

    const allAnswersPercentage = (100*getStepAnswerSum(evaluation, step))/getStepMaxSum(evaluation, step);
    const userAnswerPercentage = (100*getStepAnswerSum(evaluation, step, user))/getStepMaxSum(evaluation, step, user);
    console.log("Percentage", getStepAnswerSum(evaluation, step, user), getStepMaxSum(evaluation, step, user));

    return <MDBBtn color={hasAnswered ? "green" : "blue"}
                   onClick={() => onGotoResults(evaluation)}>{allAnswersPercentage}% {hasAnswered ? " ("+userAnswerPercentage+"%)" : ""}</MDBBtn>;
};

const StepCell = ({companiesEvaluation, evaluation, step, user, history}) => {
    if (step.finished) {
        return <StepFinished
            companiesEvaluation={companiesEvaluation}
            evaluation={evaluation}
            step={step}
            user={user}
            history={history}
        />
    }
    let canAnswer = true;
    const userIsFinished = !!step.users.find(u => u.userId === user.id && u.finished);
    const hasAnswered = !!step.answers.find(answer => answer.userId === user.id);

    const prevStep = evaluation.steps.find(s => s.position === step.comesAfter);
    if (prevStep && !prevStep.finished) canAnswer = false;
    if (canAnswer) canAnswer = !!step.users.find(u => u.userId === user.id && u.access.includes("W"));

    if (!step.started)
        return <MDBBtn color="blue" disabled>Ikke started</MDBBtn>;

    if (canAnswer)
        return <MDBBtn color={userIsFinished ? "green" : "blue"}
                       onClick={() => history.push("/evaluation/" + evaluation.id + "/" + step.id)}>{hasAnswered ? "Rediger" : "Evaluer"}</MDBBtn>

    return <MDBBtn color="blue" disabled>Under arbeid</MDBBtn>
};

const StepAnswerNumber = ({companiesEvaluation, user, step, caseStep, onUpdateAnswer, onExitAnswer}) => {
    const answer = step.answers.find(answer => answer.userId === user.id && answer.caseStepId === caseStep.id);
    if (step.finished) {
        const answers = step.answers.filter(answer => answer.caseStepId === caseStep.id);
        const sum = answers.reduce((total, a) => {
            return total + a.value;
        }, 0);
        const average = sum / answers.length;
        return <div>{answer ? 'Du: ' + answer.value : ""} <br/>Snitt: {average}</div>
    } else {
        return (
            <MDBInput label="sum"
                      disabled={!step.started || step.finished || !step.users.find(u => u.userId === user.id && u.access.includes("W"))}
                      key={step.id}
                      value={answer ? '' + answer.value : ''}
                      onBlur={(event) => onExitAnswer(step, caseStep, event.target.value)}
                      onChange={(event) => onUpdateAnswer(step, caseStep, event.target.value)}></MDBInput>
        )
    }
}

const StepAnswerStars = ({companiesEvaluation, user, step, caseStep, onUpdateAnswer, onExitAnswer}) => {
    const answer = step.answers.find(answer => answer.userId === user.id && answer.caseStepId === caseStep.id);
    const writeAccess = step.users.find(u => u.userId === user.id && u.access.includes("W"));

    const updateAnswer = (num) => {
        if (answer && answer.value === num) {
            num = 0;
        }
        onUpdateAnswer(step, caseStep, num);
        onExitAnswer(step, caseStep, num);
    }

    if (step.finished) {
        const answers = step.answers.filter(answer => answer.userId !== user.id && answer.caseStepId === caseStep.id);
        return <div>
            {answer && _.range(1, answer.value + 1).map(
                num => {
                    return <i key={user.id+"_"+num} className="fas fa-star green-text"/>
                }
            )}<br/>
            {answers && answers.map(a => {
                return <React.Fragment key={a.userId}> {_.range(1, a.value + 1).map(
                    num => {
                        return <i key={a.userId+"_"+num} className="fas fa-star blue-text"/>
                    }
                )}
                <br/>
                </React.Fragment>
            }
            )}

        </div>
    }

    if (!step.started) {
        return <div>Ikke startet</div>
    }

    if (!writeAccess) {
        return <div>Ingen tilgang</div>
    }

    return <div>{_.range(1, step.sum + 1).map(
        num => {
            let starType = "far";
            if (answer && num <= answer.value) starType = "fas green-icon";

            return <i key={num} className={starType + " fa-star pointer"} onClick={() => updateAnswer(num)}/>
        }
    )}</div>
}

const StepAnswer = ({companiesEvaluation, user, step, caseStep, onUpdateAnswer, onExitAnswer}) => {
    switch (step.type) {
        case 'number':
            return <StepAnswerNumber
                companiesEvaluation={companiesEvaluation}
                user={user}
                step={step}
                caseStep={caseStep}
                onUpdateAnswer={onUpdateAnswer}
                onExitAnswer={onExitAnswer}
            />
        case 'stars':
            return <StepAnswerStars
                companiesEvaluation={companiesEvaluation}
                user={user}
                step={step}
                caseStep={caseStep}
                onUpdateAnswer={onUpdateAnswer}
                onExitAnswer={onExitAnswer}
            />
        default:
            return <div>Ukjent</div>
    }
}

const StepSum = ({companiesEvaluation, user, step,}) => {
    return (
        "Ledige stjerner: " + (StepSumLeft({user, step}))
    );
}

const StepSumLeft = ({user, step}) => {
    const userSum = step.answers
        .filter(answer => answer.userId === user.id)
        .reduce((total, answer) => {
            return total + answer.value;
        }, 0);
    return step.sum - userSum;
}

const CompaniesEvaluation = ({companiesEvaluation, user, history, onUpdateCompaniesAnswer, onStoreCompaniesAnswer, onUserFinishStep}) => {

    const onUpdateAnswer = (step, caseStep, value) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            onUpdateCompaniesAnswer(step.id, caseStep.id, user.id, value)
        }
    };

    const onExitAnswer = (step, caseStep, value) => {
        onStoreCompaniesAnswer(step.id, caseStep.id, user.id, value);
    };

    const stopStep = async (id) => {
        await Fetch(API_BASE_URL + "/evaluation/step/stop/" + id, 'GET', {}).then(result => {
            console.log("Stopped step ", id);
        });
    }

    const stopCompaniesStep = async (id) => {
        await Fetch(API_BASE_URL + "/companies_evaluation/step/stop/" + id, 'GET', {}).then(result => {
            console.log("Stopped step ", id);
        });
    }

    return (
        <MDBCard key={companiesEvaluation.id} narrow>
            <MDBCardHeader
                className="view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                <div></div>
                <div className="white-text mx-3">Evaluering</div>
                <div>
                    <MDBBtn outline rounded size="sm" color="white" className="px-2">
                        <i className="fa fa-info-circle mt-0"></i>
                    </MDBBtn>
                </div>
            </MDBCardHeader>
            <MDBCardBody cascade>
                <MDBRow middle>
                    <MDBCol middle>Navn</MDBCol>
                    {companiesEvaluation.case.steps[0].evaluation.steps.map(
                        step =>
                            <MDBCol middle key={step.id}>
                                {step.name}
                            </MDBCol>
                    )}
                    {companiesEvaluation.steps.map(
                        step =>
                            <MDBCol middle key={"CE" + step.id}>
                                <React.Fragment>
                                    {step.name}<br/>
                                    {step.distribute ?
                                        <StepSum companiesEvaluation={companiesEvaluation} step={step}
                                                 user={user}/> :
                                        ""}
                                </React.Fragment>
                            </MDBCol>
                    )}
                </MDBRow>
                {companiesEvaluation.case.steps.map(step => {
                    const comment = step.evaluation.comments.find(c => c.userId===user.id);
                    return (
                        <React.Fragment key={step.evaluation.id}>
                        <MDBRow middle>
                            <MDBCol middle>
                                <MDBRow>
                                    <MDBCol size="10">
                                            <p>{_.isUndefined(step.evaluation.title)?"":step.evaluation.title}</p>
                                    <div>
                                        {step.evaluation.ingress+" "}
                                    </div>
                                    </MDBCol>
                                    <MDBCol size="2">
                                    { comment && comment.comment!=="" ?
                                        ""
                                        : null }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            {step.evaluation.steps.map(
                                s => <MDBCol middle key={s.id}>
                                    <StepCell
                                        companiesEvaluation={companiesEvaluation}
                                        step={s}
                                        evaluation={step.evaluation}
                                        user={user}
                                        history={history}
                                    />
                                    {
                                        companiesEvaluation.case.ownerId===user.id && !s.finished &&
                                        <MDBBtn color="red" onClick={() => stopStep(s.id)}>Avslutt</MDBBtn>
                                    }
                                </MDBCol>
                            )}
                            {companiesEvaluation.steps.map(
                                s => <MDBCol middle key={s.id}>
                                    <StepAnswer
                                        companiesEvaluation={companiesEvaluation}
                                        step={s}
                                        user={user}
                                        caseStep={step}
                                        onUpdateAnswer={onUpdateAnswer}
                                        onExitAnswer={onExitAnswer}
                                    />
                                    {
                                        companiesEvaluation.case.ownerId===user.id && !s.finished &&
                                            <MDBBtn color="red" onClick={() => stopCompaniesStep(s.id)}>Avslutt</MDBBtn>
                                    }
                                </MDBCol>
                            )}
                        </MDBRow>
                            <MDBRow>
                                <MDBCol><hr/></MDBCol>

                            </MDBRow>
                        </React.Fragment>
                    )
                })}
                <MDBRow>
                    <MDBCol/>
                    {companiesEvaluation.case.steps[0].evaluation.steps.map(step => {
                        return (
                            <MDBCol middle key={step.id}/>
                        )
                    })}
                    {companiesEvaluation.steps.map(
                        step =>
                            <MDBCol middle key={step.id}>
                                <MDBBtn color={step.users.find(u => u.userId === user.id).finished ? "green" : "blue"}
                                        disabled={step.finished || StepSumLeft({step, user}) !== 0}
                                        onClick={() => onUserFinishStep(step.id)}>Ferdig</MDBBtn>
                            </MDBCol>
                    )}
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};


class CompaniesEvaluationDashboard extends React.Component {

    componentDidMount() {
        const companiesEvaluationId = this.props.match.params.companiesEvaluationId;
        if (companiesEvaluationId) {
            this.props.setCurrentCompaniesEvaluation(companiesEvaluationId);
        }
    }

    render() {
        const {companiesEvaluation, user, history} = this.props;
        return (
            <React.Fragment>
                <div className="text-center p-4">{companiesEvaluation ? companiesEvaluation.case.title : ""}</div>
                {companiesEvaluation ? <CompaniesEvaluation history={history} user={user}
                                                            companiesEvaluation={companiesEvaluation}
                                                            onUpdateCompaniesAnswer={this.props.onUpdateCompaniesEvaluationAnswer}
                                                            onStoreCompaniesAnswer={this.props.onStoreCompaniesEvaluationAnswer}
                                                            onUserFinishStep={this.props.finishCompaniesAnsweringStep}
                /> : null}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user.user,
    companiesEvaluation: state.companiesEvaluation.current.companiesEvaluation,
});

const connected = connect(mapStateToProps, {
    setCurrentCompaniesEvaluation,
    onUpdateCompaniesEvaluationAnswer,
    onStoreCompaniesEvaluationAnswer,
    finishCompaniesAnsweringStep,
})(CompaniesEvaluationDashboard);

export default withRouter(connected);
