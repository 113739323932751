import { all } from 'redux-saga/effects'

import AuthSagas from './components/auth/sagas';
import UserSagas from './components/user/sagas';
import CasesSagas from './components/cases/sagas';
import EvaluationSagas from './components/evaluation/sagas';
import CompaniesEvaluationSagas from './components/companies-evaluation/sagas';
import SocketSagas from './modules/socket/sagas';
import AdminSagas from './components/admin/sagas';

export default function* IndexSaga () {
    yield all([
        AuthSagas(),
        UserSagas(),
        CasesSagas(),
        EvaluationSagas(),
        CompaniesEvaluationSagas(),
        SocketSagas(),
        AdminSagas()
    ])
}
