import {
    API_BASE_URL, AUTH_LOGOUT_SUCCESS,
    CASES_GET_ME_ERROR,
    CASES_GET_ME_REQUESTING,
    CASES_GET_ME_SUCCESS,
    USER_GET_ME_SUCCESS
} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import {call, put, all, takeEvery} from 'redux-saga/effects';
import {CASES_RESET_STATE} from "./constants";

function apiCasesMe() {
    return Fetch(API_BASE_URL+"/cases/me", 'GET', {});
}

function* casesGetMeWatcher() {
    let cases;
    try {
        const result = yield call(apiCasesMe);
        cases = result.data;
        yield put({type: CASES_GET_ME_SUCCESS, cases});
    } catch(error) {
        yield put({type: CASES_GET_ME_ERROR, error});
    }

    return cases;
}

function* userGetMeWatcher() {
    yield put({type: CASES_GET_ME_REQUESTING});
}

function* onLogout() {
    yield put({type: CASES_RESET_STATE});
}

function* watchAll() {
    yield all([
        takeEvery(CASES_GET_ME_REQUESTING, casesGetMeWatcher),
        takeEvery(USER_GET_ME_SUCCESS, userGetMeWatcher),
        takeEvery(AUTH_LOGOUT_SUCCESS, onLogout),
    ])
}

export default function* IndexSaga() {
    yield all([
        watchAll(),
    ])
}