import {MDBIcon, toast} from "mdbreact";
import React from "react";

export const showError = (error) => {
    toast.info(<span><MDBIcon icon={"exclamation-circle"} /> Feil: {error.toString().substr(7)}</span>, {
        closeButton: false
    })
}

export const showInfo = (info) => {
    toast.info(<span><MDBIcon icon="info-circle"/> {info}</span>,
        { closeButton: false})
}
